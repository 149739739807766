import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { TagPicker } from '~/components/TagPicker'
import translateType from '~/shared/utils/translateType'
import { ContractProjectType } from '~/types/Contracts'
import { moduleIntegrationType } from '~/types/Module'
import { get } from 'lodash'

interface Props {
    onSubmitSuccess: () => void
    onSubmitFailed?: () => void
    onCancel?: () => void
}

export default class CreateModuleForm extends Component<Props> {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        onSubmitFailed: PropTypes.func,
        onCancel: PropTypes.func,
    }

    state = {
        projectType: null,
    }

    moduleMutator

    constructor(props: Props) {
        super(props)

        this.moduleMutator = new Mutator({
            mutation: CREATE_MODULE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = (_: any, fields: any) => {
        const { onSubmitSuccess, onSubmitFailed } = this.props

        this.moduleMutator
            .mutate({
                module: transformFormFields(fields.module, {}),
            })
            .then(data => {
                if (data) {
                    onSubmitSuccess()
                    browserHistory.push(`/properties/modules/${get(data, 'modules_create._id')}`)
                } else {
                    if (onSubmitFailed) onSubmitFailed()
                }
            })
    }

    render() {
        const { errors, loading } = this.moduleMutator
        const { onCancel } = this.props
        const { projectType } = this.state

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Naam`} errors={errors}>
                            <Input name={`module.name`} type={`text`} placeholder={`Lesmodule naam`} autoFocus={true} />
                        </Field>
                        <Field title={'Type project'} errors={errors}>
                            <TagPicker
                                name={'module.projectType'}
                                placeholder={`Selecteer een type`}
                                options={[
                                    ContractProjectType.Integration,
                                    ContractProjectType.Organization,
                                    ContractProjectType.Other,
                                ].map(value => ({
                                    label: translateType('projectType', value),
                                    value: value,
                                }))}
                                multi={false}
                                onChange={this.onProjectTypeChange}
                            />
                        </Field>
                        {projectType === ContractProjectType.Integration && (
                            <FieldGroup isForm isInsetGroup={true}>
                                <Field title={'Type inburgering'} errors={errors}>
                                    <TagPicker
                                        name={'module.integrationType'}
                                        placeholder={`Selecteer een type`}
                                        options={Object.values(moduleIntegrationType).map(value => ({
                                            label: translateType('lessonModuleIntegrationType', value),
                                            value: value,
                                        }))}
                                        multi={false}
                                    />
                                </Field>
                            </FieldGroup>
                        )}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    onProjectTypeChange = (option: any) => {
        this.setState({
            projectType: option.value,
        })
    }
}

const CREATE_MODULE_MUTATION = gql`
    mutation _($module: ModuleInputType!) {
        modules_create(module: $module) {
            _id
            name
        }
    }
`
