import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  MongoID: any;
  UploadedFile: any;
};

export type AbilityLevelType = {
  __typename?: 'AbilityLevelType';
  level?: Maybe<LearnerLevelWithAlphaEnum>;
  accomplishedAt?: Maybe<Scalars['Date']>;
};

export type ActivatableGroupParticipationInvoicesType = {
  __typename?: 'ActivatableGroupParticipationInvoicesType';
  group?: Maybe<GroupType>;
  totalCost?: Maybe<Scalars['Float']>;
  lessonCost?: Maybe<Scalars['Float']>;
  lessons?: Maybe<Array<Maybe<LessonType>>>;
  lessonCount?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  contract?: Maybe<ContractType>;
};

export type AddedGroupUserInputType = {
  role?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['MongoID']>;
  addedAt?: Maybe<Scalars['Date']>;
};

export type AddressInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  nl?: Maybe<AddressNlInputType>;
  other?: Maybe<AddressOtherInputType>;
  country?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<Maybe<PhoneNumberInputType>>>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddressNlExtraInfoInputType = {
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddressNlExtraInfoType = {
  __typename?: 'AddressNLExtraInfoType';
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
};

export type AddressNlInputType = {
  number?: Maybe<Scalars['Int']>;
  numberAddition?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  manualExtraInfo?: Maybe<AddressNlExtraInfoInputType>;
  validatedExtraInfo?: Maybe<AddressNlExtraInfoInputType>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddressNlType = {
  __typename?: 'AddressNLType';
  number?: Maybe<Scalars['Int']>;
  numberAddition?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  manualExtraInfo?: Maybe<AddressNlExtraInfoType>;
  validatedExtraInfo?: Maybe<AddressNlExtraInfoType>;
  extraInfo?: Maybe<AddressNlExtraInfoType>;
};

export type AddressOtherInputType = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AddressOtherType = {
  __typename?: 'AddressOtherType';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
};

export type AddressType = {
  __typename?: 'AddressType';
  _id: Scalars['MongoID'];
  nl?: Maybe<AddressNlType>;
  other?: Maybe<AddressOtherType>;
  country?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<PhoneNumberType>>;
  /** @deprecated The new address structure should be used */
  city?: Maybe<Scalars['String']>;
  /** @deprecated The new address structure should be used */
  street?: Maybe<Scalars['String']>;
  /** @deprecated The new address structure should be used */
  number?: Maybe<Scalars['Int']>;
  /** @deprecated The new address structure should be used */
  numberAddition?: Maybe<Scalars['String']>;
  /** @deprecated The new address structure should be used */
  state?: Maybe<Scalars['String']>;
  /** @deprecated The new address structure should be used */
  zipcode?: Maybe<Scalars['String']>;
};

export type AdviceReportInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  learnerUserId?: Maybe<Scalars['MongoID']>;
  inflowMomentId?: Maybe<Scalars['MongoID']>;
  user?: Maybe<UserInputType>;
};

export type AdviceReportType = {
  __typename?: 'AdviceReportType';
  _id: Scalars['MongoID'];
  file?: Maybe<ExportResponseType>;
  learnerUser?: Maybe<UserType>;
  intakerUser?: Maybe<UserType>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  releasedAt?: Maybe<Scalars['Date']>;
  isManuallyReleased?: Maybe<Scalars['Boolean']>;
  levelReading?: Maybe<LearnerLevelWithAlphaEnum>;
  levelWriting?: Maybe<LearnerLevelWithAlphaEnum>;
  levelListening?: Maybe<LearnerLevelEnum>;
  levelTalking?: Maybe<LearnerLevelEnum>;
  levelConversations?: Maybe<LearnerLevelEnum>;
  inflowMoment?: Maybe<InflowMomentType>;
};

export enum ArticleCodesTypeEnum {
  LessonMaterials = 'lessonMaterials',
  Exams = 'exams',
  GroupParticipation = 'groupParticipation'
}

export type CalendarEventInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  type?: Maybe<Scalars['String']>;
  typeData?: Maybe<CalendarEventTypeDataInputType>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  repetition?: Maybe<CalendarEventRepetitionInputType>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CalendarEventRepetitionInputType = {
  amount?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CalendarEventType = {
  __typename?: 'CalendarEventType';
  _id: Scalars['MongoID'];
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  startWeekDay?: Maybe<Scalars['Int']>;
  endWeekDay?: Maybe<Scalars['Int']>;
};

export type CalendarEventTypeDataInputType = {
  learnerUserId?: Maybe<Scalars['MongoID']>;
  teacherUserId?: Maybe<Scalars['MongoID']>;
  roomId?: Maybe<Scalars['MongoID']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ChargeForLessonReasonOfAbsenceEnum {
  OtherIntegrationActivity = 'OTHER_INTEGRATION_ACTIVITY',
  Exam = 'EXAM',
  PrivateReasons = 'PRIVATE_REASONS',
  TransportIssues = 'TRANSPORT_ISSUES',
  Vacation = 'VACATION',
  WorkStudy = 'WORK_STUDY',
  Sick = 'SICK',
  Other = 'OTHER',
  None = 'NONE'
}

export type ContractLogType = {
  __typename?: 'ContractLogType';
  _id?: Maybe<Scalars['MongoID']>;
  type?: Maybe<ContractLogTypeEnum>;
  typeData?: Maybe<ContractLogTypeDataType>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  createdByUser?: Maybe<UserType>;
};

export type ContractLogTypeDataType = {
  __typename?: 'ContractLogTypeDataType';
  groupId?: Maybe<Scalars['MongoID']>;
  group?: Maybe<GroupType>;
};

export enum ContractLogTypeEnum {
  ContractCreated = 'contractCreated',
  ContractSigned = 'contractSigned',
  ContractSignatureChanged = 'contractSignatureChanged',
  ContractTerminated = 'contractTerminated',
  ContractMadeFinal = 'contractMadeFinal'
}

export type ContractType = {
  __typename?: 'ContractType';
  _id: Scalars['MongoID'];
  type?: Maybe<ContractTypeEnum>;
  user?: Maybe<UserType>;
  dateFrom?: Maybe<Scalars['Date']>;
  dateTo?: Maybe<Scalars['Date']>;
  contractNumber?: Maybe<Scalars['String']>;
  isSigned?: Maybe<Scalars['Boolean']>;
  isFinal?: Maybe<Scalars['Boolean']>;
  contract?: Maybe<ExportResponseType>;
  signedContract?: Maybe<ExportResponseType>;
  terminatedAt?: Maybe<Scalars['Date']>;
  terminatedReason?: Maybe<Scalars['String']>;
  logs?: Maybe<Array<Maybe<ContractLogType>>>;
};

export enum ContractTypeEnum {
  Standard = 'Standard',
  Standard150min = 'Standard150min',
  Standard180min = 'Standard180min',
  Ona = 'ONA',
  Alfa = 'Alfa',
  Alfa150min = 'Alfa150min',
  Alfa180min = 'Alfa180min',
  Stex = 'STEX',
  DuoPaidStex = 'DuoPaidSTEX',
  RouteB1 = 'RouteB1',
  RouteZ = 'RouteZ',
  StatusHolders = 'StatusHolders'
}

export type CourseMaterialInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CourseMaterialType = {
  __typename?: 'CourseMaterialType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

/** Note: manually inserted DUO declarations can only be external. Internal DUO declarations are automatically synced with Twinfield */
export type DuoDeclarationInputType = {
  amount?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['MongoID']>;
  description?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Scalars['String']>>;
};

export type DuoDeclarationType = {
  __typename?: 'DUODeclarationType';
  _id: Scalars['MongoID'];
  amount?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  isExternal?: Maybe<Scalars['Boolean']>;
  hasBeenImported?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  isFinalExamDeclaration?: Maybe<Scalars['Boolean']>;
  finalExamId?: Maybe<Scalars['MongoID']>;
  finalExam?: Maybe<FinalExamType>;
};

export type DuoFinalExamResultErrorType = {
  __typename?: 'DUOFinalExamResultErrorType';
  _id?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  examDate?: Maybe<Scalars['Date']>;
  examPart?: Maybe<FinalExamPartsEnum>;
  examLevel?: Maybe<FinalExamLevelsEnum>;
  examAttempt?: Maybe<Scalars['Int']>;
  examResult?: Maybe<FinalExamResultsEnum>;
  resolved?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ExamResultImportRowErrorTypesEnum>;
  user?: Maybe<UserType>;
  typeData?: Maybe<DuoFinalExamResultErrorTypeDataType>;
  DUOFinalExamResult?: Maybe<DuoFinalExamResultType>;
};

export type DuoFinalExamResultErrorTypeDataType = {
  __typename?: 'DUOFinalExamResultErrorTypeDataType';
  bsn?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type DuoFinalExamResultFileType = {
  __typename?: 'DUOFinalExamResultFileType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
};

export type DuoFinalExamResultType = {
  __typename?: 'DUOFinalExamResultType';
  _id?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  file?: Maybe<DuoFinalExamResultFileType>;
  createdByUser?: Maybe<UserType>;
};

export type DuoInvoiceFileType = {
  __typename?: 'DUOInvoiceFileType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
};

export type DuoInvoiceType = {
  __typename?: 'DUOInvoiceType';
  _id: Scalars['MongoID'];
  fileId?: Maybe<Scalars['MongoID']>;
  file?: Maybe<DuoInvoiceFileType>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  lastDownloadedAt?: Maybe<Scalars['Date']>;
  lastDownloadedByUserId?: Maybe<Scalars['MongoID']>;
};

export type DuoLearnerReportFileType = {
  __typename?: 'DUOLearnerReportFileType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
};

export type DuoLearnerReportType = {
  __typename?: 'DUOLearnerReportType';
  _id: Scalars['MongoID'];
  fileId?: Maybe<Scalars['MongoID']>;
  file?: Maybe<DuoLearnerReportFileType>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  createdByUser?: Maybe<UserType>;
  lastDownloadedAt?: Maybe<Scalars['Date']>;
  lastDownloadedByUserId?: Maybe<Scalars['MongoID']>;
  lastDownloadedByUser?: Maybe<UserType>;
};

export type DuoReponseInvoicesNeedActionResultType = {
  __typename?: 'DUOReponseInvoicesNeedActionResultType';
  invoice?: Maybe<InvoiceType>;
  invoiceAmountAccordingToResponse?: Maybe<Scalars['Float']>;
  response?: Maybe<Scalars['String']>;
};

export type DuoResponseFileType = {
  __typename?: 'DUOResponseFileType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
};

export type DuoResponseType = {
  __typename?: 'DUOResponseType';
  _id: Scalars['MongoID'];
  fileId?: Maybe<Scalars['MongoID']>;
  file?: Maybe<DuoResponseFileType>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  invoicesProcessedCount?: Maybe<Scalars['Int']>;
  invoicesNeedActionCount?: Maybe<Scalars['Int']>;
  invoicesNeedActionResults?: Maybe<Array<Maybe<DuoReponseInvoicesNeedActionResultType>>>;
};


export type DateRangeInputType = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  nulls?: Maybe<Array<Scalars['String']>>;
};

export type DateRangeType = {
  __typename?: 'DateRangeType';
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type EdisaImportInputType = {
  file?: Maybe<Scalars['UploadedFile']>;
  alternativeStartingPoint?: Maybe<Scalars['Int']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EdisaImportResponseType = {
  __typename?: 'EdisaImportResponseType';
  rows?: Maybe<Array<EdisaImportRowResponseType>>;
};

export type EdisaImportRowResponseType = {
  __typename?: 'EdisaImportRowResponseType';
  isExisting?: Maybe<Scalars['Boolean']>;
  hasFailed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  excelRowNumber?: Maybe<Scalars['Int']>;
  bsn?: Maybe<Scalars['String']>;
  edisaNumber?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['MongoID']>;
};

export type EdisaImportRowType = {
  __typename?: 'EdisaImportRowType';
  hasFailed?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['MongoID']>;
  edisaNumber?: Maybe<Scalars['String']>;
  learnerName?: Maybe<Scalars['String']>;
  excelRowNumber?: Maybe<Scalars['Int']>;
};

export type EdisaImportType = {
  __typename?: 'EdisaImportType';
  _id: Scalars['MongoID'];
  importedAt?: Maybe<Scalars['Date']>;
  importedByUser?: Maybe<UserType>;
  lastRow?: Maybe<EdisaImportRowType>;
};

export type EmailBounceType = {
  __typename?: 'EmailBounceType';
  recipient?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  details?: Maybe<Scalars['String']>;
};

export type EmployeeInputType = {
  isActive?: Maybe<Scalars['Boolean']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ExamAbilityDocumentFileInputType = {
  existingFileIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  files?: Maybe<Array<Maybe<Scalars['UploadedFile']>>>;
};

export type ExamAbilityInputType = {
  typeOfRating?: Maybe<ExamPartGradingTypeEnum>;
  minimumScoreToPass?: Maybe<Scalars['Float']>;
  maximumScore?: Maybe<Scalars['Float']>;
  documentFiles?: Maybe<ExamAbilityDocumentFileInputType>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ExamAbilityType = {
  __typename?: 'ExamAbilityType';
  typeOfRating?: Maybe<ExamPartGradingTypeEnum>;
  minimumScoreToPass?: Maybe<Scalars['Float']>;
  maximumScore?: Maybe<Scalars['Float']>;
  documentFiles?: Maybe<Array<Maybe<ExamDocumentFileType>>>;
};

export enum ExamAbiltiesTypeEnum {
  Converse = 'converse',
  Talk = 'talk',
  Write = 'write',
  Listen = 'listen',
  Read = 'read'
}

export type ExamDocumentFileType = {
  __typename?: 'ExamDocumentFileType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
};

export type ExamInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  level?: Maybe<ExamLevelEnum>;
  type?: Maybe<ExamTypeEnum>;
  converse?: Maybe<ExamAbilityInputType>;
  talk?: Maybe<ExamAbilityInputType>;
  write?: Maybe<ExamAbilityInputType>;
  listen?: Maybe<ExamAbilityInputType>;
  read?: Maybe<ExamAbilityInputType>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ExamLevelEnum {
  '1F' = '_1F',
  '2F' = '_2F',
  '3F' = '_3F',
  '4F' = '_4F',
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1'
}

export enum ExamPartGradingTypeEnum {
  Level = 'level',
  Score = 'score',
  PassedNotPassed = 'passedNotPassed'
}

export enum ExamResultImportRowErrorTypesEnum {
  UserNotFound = 'UserNotFound',
  ExamNotFound = 'ExamNotFound',
  ExternalFinalExamAlreadyExists = 'ExternalFinalExamAlreadyExists'
}

export type ExamType = {
  __typename?: 'ExamType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ExamTypeEnum>;
  level?: Maybe<ExamLevelEnum>;
  converse?: Maybe<ExamAbilityType>;
  talk?: Maybe<ExamAbilityType>;
  write?: Maybe<ExamAbilityType>;
  listen?: Maybe<ExamAbilityType>;
  read?: Maybe<ExamAbilityType>;
  plannedAbilityExams?: Maybe<Array<Maybe<PlannedAbilityExamType>>>;
};


export type ExamTypePlannedAbilityExamsArgs = {
  filterByGroupId?: Maybe<Scalars['MongoID']>;
};

export enum ExamTypeEnum {
  Language = 'language',
  Alpha = 'alpha'
}

export type ExplainFinalExamScoreType = {
  __typename?: 'ExplainFinalExamScoreType';
  budget?: Maybe<Scalars['Int']>;
  hours?: Maybe<Scalars['Int']>;
  module?: Maybe<Scalars['Int']>;
  months?: Maybe<Scalars['Int']>;
};

export type ExportFileType = {
  __typename?: 'ExportFileType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
};

export type ExportLastDatesType = {
  __typename?: 'ExportLastDatesType';
  EnrollmentCitizenship?: Maybe<Scalars['Date']>;
  OutflowCitizenship?: Maybe<Scalars['Date']>;
  TeachersCitizenship?: Maybe<Scalars['Date']>;
  FinalexamAttempt?: Maybe<Scalars['Date']>;
  DUOLearnerReport?: Maybe<Scalars['Date']>;
  DUOBOWEnrollmentContracts?: Maybe<Scalars['Date']>;
  DUOParticipatedHours?: Maybe<Scalars['Date']>;
};

export type ExportResponseType = {
  __typename?: 'ExportResponseType';
  _id: Scalars['MongoID'];
  fileId?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type ExportType = {
  __typename?: 'ExportType';
  _id: Scalars['MongoID'];
  fileId?: Maybe<Scalars['MongoID']>;
  file?: Maybe<ExportFileType>;
  type?: Maybe<ExportTypeEnum>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  createdByUser?: Maybe<UserType>;
  lastDownloadedAt?: Maybe<Scalars['Date']>;
  lastDownloadedByUserId?: Maybe<Scalars['MongoID']>;
  lastDownloadedByUser?: Maybe<UserType>;
};

export enum ExportTypeEnum {
  EnrollmentCitizenship = 'EnrollmentCitizenship',
  OutflowCitizenship = 'OutflowCitizenship',
  TeachersCitizenship = 'TeachersCitizenship',
  FinalexamAttempt = 'FinalexamAttempt',
  DuoLearnerReport = 'DUOLearnerReport',
  DuobowContractEnrollment = 'DUOBOWContractEnrollment',
  DuoParticipatedHours = 'DUOParticipatedHours'
}

export type FileSessionInputType = {
  fileId?: Maybe<Scalars['MongoID']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FileSessionType = {
  __typename?: 'FileSessionType';
  _id: Scalars['MongoID'];
  token?: Maybe<Scalars['String']>;
};

export type FilesInputType = {
  existingFileIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  uploadedFiles?: Maybe<Array<Maybe<Scalars['UploadedFile']>>>;
};

export type FinalExamAppointmentDocumentType = {
  __typename?: 'FinalExamAppointmentDocumentType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
  isPDF: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  createdByUser?: Maybe<UserType>;
};

export type FinalExamAttemptsInputType = {
  knm?: Maybe<Scalars['Int']>;
  ona?: Maybe<Scalars['Int']>;
  talk?: Maybe<Scalars['Int']>;
  listen?: Maybe<Scalars['Int']>;
  read?: Maybe<Scalars['Int']>;
  write?: Maybe<Scalars['Int']>;
};

export enum FinalExamCombinedPartsAndLevelsEnum {
  Knm = 'knm',
  Ona = 'ona',
  TalkA2 = 'talkA2',
  TalkB1 = 'talkB1',
  TalkB2 = 'talkB2',
  ListenA2 = 'listenA2',
  ListenB1 = 'listenB1',
  ListenB2 = 'listenB2',
  ReadA2 = 'readA2',
  ReadB1 = 'readB1',
  ReadB2 = 'readB2',
  WriteA2 = 'writeA2',
  WriteB1 = 'writeB1',
  WriteB2 = 'writeB2'
}

export enum FinalExamEditTypesEnum {
  DateChanged = 'dateChanged',
  ExamCancelled = 'examCancelled',
  DateCorrected = 'dateCorrected',
  ExamPartCorrected = 'examPartCorrected',
  SubmitterChangedToExternal = 'submitterChangedToExternal',
  SubmitterChangedToToptaal = 'submitterChangedToToptaal',
  RemoveFinalExam = 'removeFinalExam'
}

export type FinalExamInputType = {
  userId?: Maybe<Scalars['MongoID']>;
  file?: Maybe<Scalars['UploadedFile']>;
  date?: Maybe<Scalars['Date']>;
  part?: Maybe<FinalExamPartsEnum>;
  level?: Maybe<FinalExamLevelsEnum>;
  attempt?: Maybe<Scalars['Int']>;
  result?: Maybe<FinalExamResultsEnum>;
  submitter?: Maybe<FinalExamSubmittersEnum>;
  invoiceId?: Maybe<Scalars['MongoID']>;
  attempts?: Maybe<FinalExamAttemptsInputType>;
  finalExamParts?: Maybe<Array<Maybe<FinalExamPartInputType>>>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FinalExamInsertReturnType = {
  __typename?: 'FinalExamInsertReturnType';
  success?: Maybe<Scalars['Boolean']>;
  noEmailKnown?: Maybe<Scalars['Boolean']>;
};

export enum FinalExamLevelsEnum {
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2'
}

export type FinalExamLogType = {
  __typename?: 'FinalExamLogType';
  _id?: Maybe<Scalars['MongoID']>;
  type?: Maybe<FinalExamLogTypeEnum>;
  typeData?: Maybe<FinalExamLogTypeDataType>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  createdByUser?: Maybe<UserType>;
};

export type FinalExamLogTypeDataType = {
  __typename?: 'FinalExamLogTypeDataType';
  previousDate?: Maybe<Scalars['Date']>;
  invoiceId?: Maybe<Scalars['MongoID']>;
  invoice?: Maybe<InvoiceType>;
  duoDeclarationId?: Maybe<Scalars['MongoID']>;
  duoDeclaration?: Maybe<DuoDeclarationType>;
  previousExamPart?: Maybe<FinalExamPartsEnum>;
  duoFinalExamResultId?: Maybe<Scalars['MongoID']>;
  duoFinalExamResult?: Maybe<DuoFinalExamResultType>;
};

export enum FinalExamLogTypeEnum {
  ExamCreated = 'examCreated',
  InvoiceCreated = 'invoiceCreated',
  InvoiceSent = 'invoiceSent',
  InvoiceCredited = 'invoiceCredited',
  DuoDeclarationCreated = 'duoDeclarationCreated',
  DuoDeclarationRevoked = 'duoDeclarationRevoked',
  DateChanged = 'dateChanged',
  ExamCancelled = 'examCancelled',
  DateCorrected = 'dateCorrected',
  ExamPartCorrected = 'examPartCorrected',
  SubmitterChangedToExternal = 'submitterChangedToExternal',
  SubmitterChangedToToptaal = 'submitterChangedToToptaal',
  CreatedByDuoFinalExamResultImport = 'createdByDUOFinalExamResultImport',
  ResultUpdatedFromDuoFinalExamResultImport = 'resultUpdatedFromDUOFinalExamResultImport',
  UncanceledByDuoFinalExamResultImport = 'uncanceledByDUOFinalExamResultImport',
  CreatedAsExemptByDuoFinalExamResultImport = 'createdAsExemptByDUOFinalExamResultImport'
}

export type FinalExamNeededType = {
  __typename?: 'FinalExamNeededType';
  budgetRemaining?: Maybe<Scalars['Float']>;
  hoursPresent?: Maybe<Scalars['Int']>;
  endOfIntegrationTerm?: Maybe<Scalars['Date']>;
  module?: Maybe<ModuleType>;
  finalExams?: Maybe<Array<Maybe<FinalExamType>>>;
  explainScore?: Maybe<ExplainFinalExamScoreType>;
};


export type FinalExamNeededTypeFinalExamsArgs = {
  filters?: Maybe<FinalExamsFilterInputType>;
};

export type FinalExamPartInputType = {
  partAndLevel?: Maybe<FinalExamCombinedPartsAndLevelsEnum>;
  date?: Maybe<Scalars['Date']>;
};

export enum FinalExamPartsEnum {
  Knm = 'knm',
  Ona = 'ona',
  Talk = 'talk',
  Write = 'write',
  Listen = 'listen',
  Read = 'read'
}

export enum FinalExamResultsEnum {
  Passed = 'passed',
  Failed = 'failed',
  NoShow = 'no_show',
  Exempt = 'exempt'
}

export enum FinalExamSubmittersEnum {
  Toptaal = 'toptaal',
  External = 'external'
}

export type FinalExamType = {
  __typename?: 'FinalExamType';
  _id: Scalars['MongoID'];
  userId?: Maybe<Scalars['MongoID']>;
  user?: Maybe<UserType>;
  date?: Maybe<Scalars['Date']>;
  part?: Maybe<FinalExamPartsEnum>;
  level?: Maybe<FinalExamLevelsEnum>;
  attempt?: Maybe<Scalars['Int']>;
  result?: Maybe<FinalExamResultsEnum>;
  submitter?: Maybe<FinalExamSubmittersEnum>;
  invoice?: Maybe<InvoiceType>;
  isInvoiceActivatable?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUserId?: Maybe<Scalars['MongoID']>;
  createdByUser?: Maybe<UserType>;
  logs?: Maybe<Array<Maybe<FinalExamLogType>>>;
  canceledAt?: Maybe<Scalars['Date']>;
  appointmentFile?: Maybe<FinalExamAppointmentDocumentType>;
  nextAttempt?: Maybe<FinalExamType>;
  cost?: Maybe<Scalars['Float']>;
};

export type FinalExamsFilterInputType = {
  byUserId?: Maybe<Scalars['MongoID']>;
  filterByExamIsNotCanceled?: Maybe<Scalars['Boolean']>;
  filterByExameDateFrom?: Maybe<Scalars['Date']>;
  filterByExameDateTo?: Maybe<Scalars['Date']>;
};

export type FinalExamsNeededFilterInputType = {
  filterByGroupEmployeeId?: Maybe<Scalars['MongoID']>;
  filterByModuleId?: Maybe<Scalars['MongoID']>;
  searchByName?: Maybe<Scalars['String']>;
  filterByEndTerm?: Maybe<Scalars['Int']>;
  filterByOrganizationId?: Maybe<Scalars['MongoID']>;
  filterByOrganizationContactId?: Maybe<Scalars['MongoID']>;
};

export type FullDutchLocation = {
  __typename?: 'FullDutchLocation';
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  numberAddition?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
};

export type GroupDocumentInputType = {
  file?: Maybe<Scalars['UploadedFile']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupDocumentType = {
  __typename?: 'GroupDocumentType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
  isPDF: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  createdByUser?: Maybe<UserType>;
};

export type GroupInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['MongoID']>;
  programId?: Maybe<Scalars['MongoID']>;
  moduleId?: Maybe<Scalars['MongoID']>;
  locationId?: Maybe<Scalars['MongoID']>;
  roomIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  startWeekDate?: Maybe<Scalars['Date']>;
  generalWeekLessonDates?: Maybe<Array<Scalars['Date']>>;
  generalWeekLessonRoomIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  generalWeekLessonTeacherUserIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  startDateIndex?: Maybe<Scalars['Int']>;
  localizedExtraLearnerInformation?: Maybe<LocalizedExtraLearnerInformationInputType>;
  dateBlockers?: Maybe<Array<DateRangeInputType>>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupInviteLogType = {
  __typename?: 'GroupInviteLogType';
  _id: Scalars['MongoID'];
  user?: Maybe<UserType>;
  date?: Maybe<Scalars['Date']>;
  type?: Maybe<InflowMomentInviteType>;
  invitedByUser?: Maybe<UserType>;
  groupInviteTemplate?: Maybe<GroupInviteTemplateType>;
};

export type GroupInviteTemnplateInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  moduleId?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<GroupInviteTemplateFileInputType>>>;
  nl?: Maybe<GroupInviteTemplateTranslatableFieldsInputType>;
  en?: Maybe<GroupInviteTemplateTranslatableFieldsInputType>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupInviteTemplateAttachmentType = {
  __typename?: 'GroupInviteTemplateAttachmentType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
};

export type GroupInviteTemplateFileInputType = {
  existingFileIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  uploadedFiles?: Maybe<Array<Maybe<Scalars['UploadedFile']>>>;
};

export type GroupInviteTemplateTranslatableFieldType = {
  __typename?: 'GroupInviteTemplateTranslatableFieldType';
  content?: Maybe<Scalars['String']>;
};

export type GroupInviteTemplateTranslatableFieldsInputType = {
  content?: Maybe<Scalars['String']>;
};

export type GroupInviteTemplateType = {
  __typename?: 'GroupInviteTemplateType';
  _id: Scalars['MongoID'];
  module?: Maybe<ModuleType>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  nl?: Maybe<GroupInviteTemplateTranslatableFieldType>;
  en?: Maybe<GroupInviteTemplateTranslatableFieldType>;
  attachments?: Maybe<Array<Maybe<GroupInviteTemplateAttachmentType>>>;
};

export type GroupTimetableLessonType = {
  __typename?: 'GroupTimetableLessonType';
  generalWeekLessonDateIndex?: Maybe<Scalars['Int']>;
  weekIndex?: Maybe<Scalars['Int']>;
  lesson?: Maybe<LessonType>;
};

export type GroupType = {
  __typename?: 'GroupType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
  isConcept?: Maybe<Scalars['Boolean']>;
  isLegacyGroup?: Maybe<Scalars['Boolean']>;
  endedAt?: Maybe<Scalars['Date']>;
  hasParticipantsWithExpiredInvoices?: Maybe<Scalars['Boolean']>;
  hasParticipantsWithInvoicingDisabled?: Maybe<Scalars['Boolean']>;
  hasParticipantsWithInactiveInvoices?: Maybe<Scalars['Boolean']>;
  hasParticipantsThatRequireSignatureOnContract?: Maybe<Scalars['Boolean']>;
  hasParticipantsThatRequireSignatureOnInvoice?: Maybe<Scalars['Boolean']>;
  hasParticipantsWithInvoicesThatAreDeniedByDUO?: Maybe<Scalars['Boolean']>;
  isEndAllowed?: Maybe<Scalars['Boolean']>;
  project?: Maybe<ProjectType>;
  program?: Maybe<ProgramType>;
  module?: Maybe<ModuleType>;
  location?: Maybe<LocationType>;
  rooms?: Maybe<Array<Maybe<RoomType>>>;
  dateFrom?: Maybe<Scalars['Date']>;
  dateTo?: Maybe<Scalars['Date']>;
  startWeekDate?: Maybe<Scalars['Date']>;
  generalWeekLessonDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
  generalWeekLessonRooms?: Maybe<Array<Maybe<RoomType>>>;
  generalWeekLessonTeacherUsers?: Maybe<Array<Maybe<UserType>>>;
  startDateIndex?: Maybe<Scalars['Int']>;
  learnerUsers?: Maybe<Array<Maybe<GroupUserType>>>;
  latestInvites?: Maybe<Array<Maybe<GroupInviteLogType>>>;
  amountOfUnsentLessonInvites?: Maybe<Scalars['Int']>;
  learnerUsersCount?: Maybe<Scalars['Int']>;
  teacherUsers?: Maybe<Array<Maybe<GroupUserType>>>;
  employeeUsers?: Maybe<Array<Maybe<GroupUserType>>>;
  timetableLessons?: Maybe<Array<Maybe<GroupTimetableLessonType>>>;
  lessons?: Maybe<Array<Maybe<LessonType>>>;
  localizedExtraLearnerInformation?: Maybe<LocalizedExtraLearnerInformationType>;
  dateBlockers?: Maybe<Array<Maybe<DateRangeType>>>;
  canBeDeleted?: Maybe<Scalars['Boolean']>;
  documents?: Maybe<Array<Maybe<GroupDocumentType>>>;
  notes?: Maybe<Array<Maybe<NoteType>>>;
  notesCount?: Maybe<Scalars['Int']>;
  hasExamWhichRequiresAction?: Maybe<Scalars['Boolean']>;
};


export type GroupTypeLearnerUsersArgs = {
  filterByUserIds?: Maybe<Array<Scalars['MongoID']>>;
  filterRemoved?: Maybe<Scalars['Boolean']>;
};


export type GroupTypeLearnerUsersCountArgs = {
  filterByUserIds?: Maybe<Array<Scalars['MongoID']>>;
};


export type GroupTypeTeacherUsersArgs = {
  filterByUserIds?: Maybe<Array<Scalars['MongoID']>>;
  filterRemoved?: Maybe<Scalars['Boolean']>;
};


export type GroupTypeEmployeeUsersArgs = {
  filterByUserIds?: Maybe<Array<Scalars['MongoID']>>;
  filterRemoved?: Maybe<Scalars['Boolean']>;
};


export type GroupTypeLessonsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  nextLessonForTeacherUserId?: Maybe<Scalars['MongoID']>;
  filterByIsAvailableToPlanExamId?: Maybe<Scalars['MongoID']>;
  searchText?: Maybe<Scalars['String']>;
  filterByTeacherId?: Maybe<Scalars['MongoID']>;
};


export type GroupTypeDocumentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};

export enum GroupUserDisenrollmentFollowupActionEnum {
  NoAction = 'NoAction',
  DeactiveLearnerUser = 'DeactiveLearnerUser',
  ChangeLearnerUserAdviceModule = 'ChangeLearnerUserAdviceModule'
}

export type GroupUserDisenrollmentNewAdviceModuleInputType = {
  moduleId?: Maybe<Scalars['MongoID']>;
  projectId?: Maybe<Scalars['MongoID']>;
  programId?: Maybe<Scalars['MongoID']>;
};

export type GroupUserEnrollmentType = {
  __typename?: 'GroupUserEnrollmentType';
  _id: Scalars['MongoID'];
  role?: Maybe<GroupUserRoleEnum>;
  type?: Maybe<GroupUserEnrollmentTypeEnum>;
  removedReason?: Maybe<GroupUserRemovedReasonEnum>;
  removedInfo?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUser?: Maybe<UserType>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedByUser?: Maybe<UserType>;
};

export enum GroupUserEnrollmentTypeEnum {
  Enrollment = 'Enrollment',
  Disenrollment = 'Disenrollment'
}

export enum GroupUserRemovedReasonEnum {
  FinishedSuccesful = 'FinishedSuccesful',
  FinishedUnsuccesful = 'FinishedUnsuccesful',
  EarlyDisenrollmentLevelUnsuitable = 'EarlyDisenrollmentLevelUnsuitable',
  EarlyDisenrollmentOtherPersonalReasons = 'EarlyDisenrollmentOtherPersonalReasons',
  ReturnBeforeStart = 'ReturnBeforeStart',
  EarlyDisenrollmentUnavailable = 'EarlyDisenrollmentUnavailable',
  EarlyDisenrollmentMedical = 'EarlyDisenrollmentMedical',
  EarlyDisenrollmentMotivation = 'EarlyDisenrollmentMotivation',
  EarlyDisenrollmentWentToOtherSchool = 'EarlyDisenrollmentWentToOtherSchool',
  EarlyDisenrollmentFoundWork = 'EarlyDisenrollmentFoundWork',
  EarlyDisenrollmentMovedHouse = 'EarlyDisenrollmentMovedHouse',
  EarlyDisenrollmentUnreachable = 'EarlyDisenrollmentUnreachable',
  EarlyDisenrollmentLeavingEmployer = 'EarlyDisenrollmentLeavingEmployer',
  EarlyDisenrollmentNotSatisfiedWithTopTaal = 'EarlyDisenrollmentNotSatisfiedWithTopTaal',
  ExemptionRoute = 'ExemptionRoute',
  OnHold = 'OnHold',
  Other = 'Other',
  AlmostSuccessfull = 'AlmostSuccessfull'
}

export enum GroupUserRoleEnum {
  Learner = 'LEARNER',
  Teacher = 'TEACHER',
  Employee = 'EMPLOYEE'
}

export type GroupUserType = {
  __typename?: 'GroupUserType';
  _id: Scalars['MongoID'];
  user?: Maybe<UserType>;
  role?: Maybe<Scalars['String']>;
  hasSignedContractForEveryLessonInGroup?: Maybe<Scalars['Boolean']>;
  learnerAttendanceRate?: Maybe<Scalars['Float']>;
  isNowEnrolled?: Maybe<Scalars['Boolean']>;
  hasLearnerFutureEnrollment?: Maybe<Scalars['Boolean']>;
  hasLearnerFinalEnrollment?: Maybe<Scalars['Boolean']>;
  enrollments?: Maybe<Array<Maybe<GroupUserEnrollmentType>>>;
  lessonInvitePdfDownloadedAt?: Maybe<GroupInviteLogType>;
  lessonInviteEmailSentAt?: Maybe<GroupInviteLogType>;
  hasAttendances?: Maybe<Scalars['Boolean']>;
  hasFinishedGroup?: Maybe<Scalars['Boolean']>;
  isGroupParticipationInvoiceActivatable?: Maybe<Scalars['Boolean']>;
  hasAttendancesAfterLastEnrollment?: Maybe<Scalars['Boolean']>;
  hasInvoicesAfterLastEnrollment?: Maybe<Scalars['Boolean']>;
};


export type GroupUserTypeHasAttendancesArgs = {
  afterDate?: Maybe<Scalars['Date']>;
};

export type GroupsFilterInputType = {
  filterById?: Maybe<Scalars['MongoID']>;
  filterByConcepts?: Maybe<Scalars['Boolean']>;
  filterByIsStarted?: Maybe<Scalars['Boolean']>;
  filterByIsEnded?: Maybe<Scalars['Boolean']>;
  filterByHasInvoices?: Maybe<Scalars['Boolean']>;
  filterByTextSearch?: Maybe<Scalars['String']>;
  filterByProjectIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  filterByLocationIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  filterByTeacherUserIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  filterByProgramIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  filterByModuleIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  filterByEmployeeUserIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  filterByLearnerOrganizationIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  filterByStartDateFrom?: Maybe<Scalars['Date']>;
  filterByStartDateTo?: Maybe<Scalars['Date']>;
  filterByEndDateFrom?: Maybe<Scalars['Date']>;
  filterByEndDateTo?: Maybe<Scalars['Date']>;
};

export type InflowInviteTemnplateInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  inflowModuleId?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<InflowInviteTemplateFileInputType>>>;
  nl?: Maybe<InflowInviteTemplateTranslatableFieldsInputType>;
  en?: Maybe<InflowInviteTemplateTranslatableFieldsInputType>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InflowInviteTemplateAttachmentType = {
  __typename?: 'InflowInviteTemplateAttachmentType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
};

export type InflowInviteTemplateFileInputType = {
  existingFileIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  uploadedFiles?: Maybe<Array<Maybe<Scalars['UploadedFile']>>>;
};

export type InflowInviteTemplateTranslatableFieldType = {
  __typename?: 'InflowInviteTemplateTranslatableFieldType';
  content?: Maybe<Scalars['String']>;
};

export type InflowInviteTemplateTranslatableFieldsInputType = {
  content?: Maybe<Scalars['String']>;
};

export type InflowInviteTemplateType = {
  __typename?: 'InflowInviteTemplateType';
  _id: Scalars['MongoID'];
  inflowModule?: Maybe<InflowModuleType>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  nl?: Maybe<InflowInviteTemplateTranslatableFieldType>;
  en?: Maybe<InflowInviteTemplateTranslatableFieldType>;
  attachments?: Maybe<Array<Maybe<InflowInviteTemplateAttachmentType>>>;
};

export type InflowLevelsInputType = {
  levelReading?: Maybe<LearnerLevelWithAlphaEnum>;
  levelWriting?: Maybe<LearnerLevelWithAlphaEnum>;
  levelListening?: Maybe<LearnerLevelEnum>;
  levelTalking?: Maybe<LearnerLevelEnum>;
  levelConversations?: Maybe<LearnerLevelEnum>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InflowLevelsType = {
  __typename?: 'InflowLevelsType';
  levelReading?: Maybe<Scalars['String']>;
  levelWriting?: Maybe<Scalars['String']>;
  levelListening?: Maybe<Scalars['String']>;
  levelTalking?: Maybe<Scalars['String']>;
  levelConversations?: Maybe<Scalars['String']>;
};

export type InflowModuleExamAbilitiesInputType = {
  converse?: Maybe<Scalars['Boolean']>;
  listen?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  talk?: Maybe<Scalars['Boolean']>;
  write?: Maybe<Scalars['Boolean']>;
};

export type InflowModuleExamAbilitiesType = {
  __typename?: 'InflowModuleExamAbilitiesType';
  converse?: Maybe<Scalars['Boolean']>;
  listen?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  talk?: Maybe<Scalars['Boolean']>;
  write?: Maybe<Scalars['Boolean']>;
};

export type InflowModuleExamInputType = {
  examId?: Maybe<Scalars['MongoID']>;
  abilities?: Maybe<InflowModuleExamAbilitiesInputType>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InflowModuleExamType = {
  __typename?: 'InflowModuleExamType';
  examId?: Maybe<Scalars['MongoID']>;
  abilities?: Maybe<InflowModuleExamAbilitiesType>;
  exam?: Maybe<ExamType>;
};

export type InflowModuleInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InflowModuleTypeEnum>;
  examMaxTimeMinutes?: Maybe<Scalars['Int']>;
  examStartIntervalMinutes?: Maybe<Scalars['Int']>;
  exams?: Maybe<Array<InflowModuleExamInputType>>;
  maxStudentsPerStart?: Maybe<Scalars['Int']>;
  checkAdviceReportNeeded?: Maybe<Scalars['Boolean']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InflowModuleType = {
  __typename?: 'InflowModuleType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<InflowModuleTypeEnum>;
  exams?: Maybe<Array<Maybe<InflowModuleExamType>>>;
  examMaxTimeMinutes?: Maybe<Scalars['Int']>;
  examStartIntervalMinutes?: Maybe<Scalars['Int']>;
  maxStudentsPerStart?: Maybe<Scalars['Int']>;
  checkAdviceReportNeeded?: Maybe<Scalars['Boolean']>;
  isLockedForEdit?: Maybe<Scalars['Boolean']>;
  isLockedForDelete?: Maybe<Scalars['Boolean']>;
};

export enum InflowModuleTypeEnum {
  Intake = 'intake',
  LevelDetermination = 'levelDetermination'
}

export type InflowMomentDateRangeInputType = {
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type InflowMomentDateRangeType = {
  __typename?: 'InflowMomentDateRangeType';
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type InflowMomentFilterInputType = {
  filterByInflowModule?: Maybe<Scalars['MongoID']>;
  filterByLocation?: Maybe<Scalars['MongoID']>;
  filterByOrganization?: Maybe<Scalars['MongoID']>;
  filterByDateFrom?: Maybe<Scalars['Date']>;
  filterByDateTo?: Maybe<Scalars['Date']>;
  filterByCreatedBy?: Maybe<Scalars['MongoID']>;
  filterByConcepts?: Maybe<Scalars['Boolean']>;
  filterById?: Maybe<Scalars['MongoID']>;
  filterByOrganizationsAndIncludeNulls?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  filterByOrganizations?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  filterByNoOrganization?: Maybe<Scalars['Boolean']>;
  filterBySlotsAvailable?: Maybe<Scalars['Boolean']>;
};

export type InflowMomentInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  inflowModuleId?: Maybe<Scalars['MongoID']>;
  isForOrganization?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['MongoID']>;
  isConcept?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['MongoID']>;
  locationRoomsOrganized?: Maybe<Scalars['Boolean']>;
  locationRoomsConversationIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  locationRoomsGeneralIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  dateRange?: Maybe<InflowMomentDateRangeInputType>;
  intakerUserIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  employeeUserIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InflowMomentInviteInvitesInputType = {
  userId?: Maybe<Scalars['MongoID']>;
  type?: Maybe<InflowMomentInviteType>;
};

export type InflowMomentInviteLogType = {
  __typename?: 'InflowMomentInviteLogType';
  _id: Scalars['MongoID'];
  user?: Maybe<UserType>;
  date?: Maybe<Scalars['Date']>;
  type?: Maybe<InflowMomentInviteType>;
  invitedByUser?: Maybe<UserType>;
  inflowInviteTemplate?: Maybe<InflowInviteTemplateType>;
};

export enum InflowMomentInviteType {
  Manual = 'Manual',
  Email = 'Email'
}

export type InflowMomentProjectedTimeslotSlotType = {
  __typename?: 'InflowMomentProjectedTimeslotSlotType';
  number?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  duration?: Maybe<Scalars['Int']>;
};

export type InflowMomentProjectedTimeslotType = {
  __typename?: 'InflowMomentProjectedTimeslotType';
  totalPlaces?: Maybe<Scalars['Int']>;
  timeslots?: Maybe<Array<Maybe<InflowMomentProjectedTimeslotSlotType>>>;
};

export type InflowMomentTimeslotInputType = {
  inflowMomentTimeslotId?: Maybe<Scalars['MongoID']>;
  userIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type InflowMomentTimeslotType = {
  __typename?: 'InflowMomentTimeslotType';
  _id?: Maybe<Scalars['MongoID']>;
  inflowMoment?: Maybe<InflowMomentType>;
  startTime?: Maybe<Scalars['Date']>;
  endTime?: Maybe<Scalars['Date']>;
  duration?: Maybe<Scalars['Int']>;
  maxUsers?: Maybe<Scalars['Int']>;
  timeslotUsers?: Maybe<Array<Maybe<InflowMomentTimeslotUserType>>>;
};

export enum InflowMomentTimeslotUserStatusEnum {
  Present = 'Present',
  NoShow = 'NoShow'
}

export type InflowMomentTimeslotUserType = {
  __typename?: 'InflowMomentTimeslotUserType';
  _id?: Maybe<Scalars['MongoID']>;
  user?: Maybe<UserType>;
  status?: Maybe<InflowMomentTimeslotUserStatusEnum>;
};

export type InflowMomentType = {
  __typename?: 'InflowMomentType';
  _id: Scalars['MongoID'];
  isConcept?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUser?: Maybe<UserType>;
  hasStarted?: Maybe<Scalars['Boolean']>;
  inflowModule?: Maybe<InflowModuleType>;
  isForOrganization?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationType>;
  location?: Maybe<LocationType>;
  locationRoomsOrganized?: Maybe<Scalars['Boolean']>;
  locationRoomsConversation?: Maybe<Array<Maybe<RoomType>>>;
  locationRoomsGeneral?: Maybe<Array<Maybe<RoomType>>>;
  dateRange?: Maybe<InflowMomentDateRangeType>;
  intakerUsers?: Maybe<Array<Maybe<UserType>>>;
  employeeUsers?: Maybe<Array<Maybe<UserType>>>;
  timeslotsProjected?: Maybe<InflowMomentProjectedTimeslotType>;
  timeslots?: Maybe<Array<Maybe<InflowMomentTimeslotType>>>;
  amountOfSlotsPerTimeslot?: Maybe<Scalars['Int']>;
  candidateCountUsed?: Maybe<Scalars['Int']>;
  candidateCountTotal?: Maybe<Scalars['Int']>;
  candidates?: Maybe<Array<Maybe<UserType>>>;
  canHaveNewTimeslots?: Maybe<Scalars['Boolean']>;
  minimumTeachersNeeded?: Maybe<Scalars['Int']>;
  latestInvites?: Maybe<Array<Maybe<InflowMomentInviteLogType>>>;
  candidatesInviteNeededCount?: Maybe<Scalars['Int']>;
  adviceReports?: Maybe<Array<Maybe<AdviceReportType>>>;
  hasUnreleasedAdviceReports?: Maybe<Scalars['Boolean']>;
};


export type InflowMomentTypeAdviceReportsArgs = {
  filterByLearnerUserId?: Maybe<Scalars['MongoID']>;
};

export type InfoType = {
  __typename?: 'InfoType';
  isTwinfieldTokenSet?: Maybe<Scalars['Boolean']>;
};

export type InstructionVideoInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  targetRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InstructionVideoType = {
  __typename?: 'InstructionVideoType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
  targetRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['String']>;
};

export enum IntegrationTypeEnum {
  Ona = 'ONA',
  Standard = 'Standard',
  Alfa = 'Alfa',
  StateExam = 'StateExam'
}

export enum InvoiceDescriptionEnum {
  LessonMaterials = 'lessonMaterials'
}

export enum InvoiceFilterStatusEnum {
  StatusDraft = 'StatusDraft',
  OpenStatusNeedsSignatureForDuo = 'OpenStatusNeedsSignatureForDUO',
  OpenStatusWaitingForDuoExportBatch = 'OpenStatusWaitingForDUOExportBatch',
  OpenStatusWaitingForPayment = 'OpenStatusWaitingForPayment',
  OpenStatusWaitingForDuoApproval = 'OpenStatusWaitingForDUOApproval',
  OpenStatusRejectedByDuo = 'OpenStatusRejectedByDUO',
  PaymentStatusPaid = 'PaymentStatusPaid',
  DebitStatusCanceled = 'DebitStatusCanceled',
  CreditStatusCanceled = 'CreditStatusCanceled'
}

export type InvoiceInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  isCredit?: Maybe<Scalars['Boolean']>;
  debitInvoiceId?: Maybe<Scalars['MongoID']>;
  description?: Maybe<InvoiceDescriptionEnum>;
  creditDescription?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['MongoID']>;
  groupId?: Maybe<Scalars['MongoID']>;
  /** This field is used to specify an alternative amount. */
  amount?: Maybe<Scalars['Float']>;
  /** This field is required when the alternative amount is specified. */
  alternativeAmountDescription?: Maybe<Scalars['String']>;
  installments?: Maybe<Scalars['Int']>;
  isGroupParticipationInvoice?: Maybe<Scalars['Boolean']>;
  isFinalExamInvoice?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
  finalExamId?: Maybe<Scalars['MongoID']>;
  courseMaterialIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  lessonIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type InvoiceLogType = {
  __typename?: 'InvoiceLogType';
  _id?: Maybe<Scalars['MongoID']>;
  type?: Maybe<InvoiceLogTypeEnum>;
  typeData?: Maybe<InvoiceLogTypeDataType>;
  triggeredByUser?: Maybe<UserType>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type InvoiceLogTypeDataNewOpenInvoiceStatusInfoType = {
  __typename?: 'InvoiceLogTypeDataNewOpenInvoiceStatusInfoType';
  responseByDUO?: Maybe<Scalars['String']>;
};

export type InvoiceLogTypeDataType = {
  __typename?: 'InvoiceLogTypeDataType';
  reminderCount?: Maybe<Scalars['Int']>;
  newInvoiceStatus?: Maybe<InvoiceStatusEnum>;
  newOpenInvoiceStatus?: Maybe<OpenInvoiceStatusEnum>;
  newOpenInvoiceStatusInfo?: Maybe<InvoiceLogTypeDataNewOpenInvoiceStatusInfoType>;
  newInvoicePaymentStatus?: Maybe<InvoicePaymentStatusEnum>;
  newAmount?: Maybe<Scalars['Float']>;
  previousAmount?: Maybe<Scalars['Float']>;
};

export enum InvoiceLogTypeEnum {
  CreateInvoice = 'createInvoice',
  CreateCreditInvoice = 'createCreditInvoice',
  ChangeInvoice = 'changeInvoice',
  ChangeInvoiceStatus = 'changeInvoiceStatus',
  ChangeOpenInvoiceStatus = 'changeOpenInvoiceStatus',
  ChangeInvoicePaymentStatus = 'changeInvoicePaymentStatus',
  SendInvoice = 'sendInvoice',
  MarkInvoiceAsFullyCredited = 'markInvoiceAsFullyCredited',
  MarkInvoiceAsPartlyCredited = 'markInvoiceAsPartlyCredited',
  SendInvoiceReminder = 'sendInvoiceReminder',
  SignatureAddedToInvoice = 'signatureAddedToInvoice',
  SignatureChangedForInvoice = 'signatureChangedForInvoice'
}

export enum InvoicePaymentStatusEnum {
  Open = 'Open',
  Paid = 'Paid'
}

export enum InvoicePrivatePaymentTypeEnum {
  Duo = 'duo',
  Self = 'self'
}

export enum InvoiceStatusEnum {
  Canceled = 'Canceled',
  Draft = 'Draft',
  Final = 'Final'
}

export type InvoiceType = {
  __typename?: 'InvoiceType';
  _id: Scalars['MongoID'];
  isCredit: Scalars['Boolean'];
  debitInvoice?: Maybe<InvoiceType>;
  invoiceNumber?: Maybe<Scalars['String']>;
  description?: Maybe<InvoiceDescriptionEnum>;
  installmentIndex?: Maybe<Scalars['Int']>;
  creditDescription?: Maybe<Scalars['String']>;
  isGroupParticipationInvoice: Scalars['Boolean'];
  creditableAmountPerLesson?: Maybe<Scalars['Float']>;
  isFinalExamInvoice: Scalars['Boolean'];
  finalExam?: Maybe<FinalExamType>;
  group?: Maybe<GroupType>;
  user?: Maybe<UserType>;
  amount?: Maybe<Scalars['Float']>;
  alternativeAmountDescription?: Maybe<Scalars['String']>;
  /** Get a sum of the amounts across all eventual installments */
  installmentsTotal?: Maybe<Scalars['Float']>;
  conceptCreditedAmount?: Maybe<Scalars['Float']>;
  creditedAmount?: Maybe<Scalars['Float']>;
  invoiceDate?: Maybe<Scalars['Date']>;
  expirationDate?: Maybe<Scalars['Date']>;
  status: InvoiceStatusEnum;
  statusChangedAt: Scalars['Date'];
  openStatus?: Maybe<OpenInvoiceStatusEnum>;
  openStatusChangedAt: Scalars['Date'];
  paymentStatus?: Maybe<InvoicePaymentStatusEnum>;
  paymentStatusChangedAt: Scalars['Date'];
  notes?: Maybe<Scalars['String']>;
  isExpired: Scalars['Boolean'];
  daysExpired: Scalars['Int'];
  hasSignatureForDUO: Scalars['Boolean'];
  signatureForDuoFile?: Maybe<SignatureFileType>;
  isForDUO: Scalars['Boolean'];
  invoiceSentAt?: Maybe<Scalars['Date']>;
  logs?: Maybe<Array<Maybe<InvoiceLogType>>>;
  createdAt?: Maybe<Scalars['Date']>;
  descendantInstallmentInvoices?: Maybe<Array<InvoiceType>>;
  hasMultipleInstallments: Scalars['Boolean'];
  installments?: Maybe<Scalars['Int']>;
  installmentLeadingInvoice?: Maybe<InvoiceType>;
  installmentsSubTotal?: Maybe<Scalars['Float']>;
  isDescendantInstallment: Scalars['Boolean'];
  isDownloadable: Scalars['Boolean'];
  isFullyCredited: Scalars['Boolean'];
  mayBeCredited: Scalars['Boolean'];
  remainingConceptCreditableAmount?: Maybe<Scalars['Float']>;
  isEmailRequiredToSend: Scalars['Boolean'];
  emailedAt?: Maybe<Scalars['Date']>;
  mayBeCanceled: Scalars['Boolean'];
  descendantInstallmentInvoiceHasFailedToSend?: Maybe<Scalars['Boolean']>;
  courseMaterials?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  lessons?: Maybe<Array<Maybe<LessonType>>>;
  invoicedLessonUsers?: Maybe<Array<Maybe<InvoicedLessonUserType>>>;
  contract?: Maybe<ContractType>;
};

export enum InvoiceTypeEnum {
  GroupParticipation = 'GroupParticipation',
  FinalExam = 'FinalExam',
  LessonMaterials = 'LessonMaterials'
}

export type InvoicedLessonUserType = {
  __typename?: 'InvoicedLessonUserType';
  _id: Scalars['MongoID'];
  group?: Maybe<GroupType>;
  lessonId?: Maybe<Scalars['MongoID']>;
  lessonDate?: Maybe<Scalars['Date']>;
  lastDebitInvoiceIdForCreditableLessonUser?: Maybe<Scalars['MongoID']>;
  lesson?: Maybe<LessonType>;
  user?: Maybe<UserType>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type InvoicesFilterInputType = {
  byIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  byTextSearch?: Maybe<Scalars['String']>;
  byCredit?: Maybe<Scalars['Boolean']>;
  byStatus?: Maybe<InvoiceStatusEnum>;
  byOpenStatus?: Maybe<OpenInvoiceStatusEnum>;
  byPaymentStatus?: Maybe<InvoicePaymentStatusEnum>;
  byFilterStatus?: Maybe<InvoiceFilterStatusEnum>;
  byGroupIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  byInvoiceTypes?: Maybe<Array<InvoiceTypeEnum>>;
  byProjectIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  filterByGroupEmployeeUserIds?: Maybe<Array<Scalars['MongoID']>>;
  byInvoiceDateFrom?: Maybe<Scalars['Date']>;
  byInvoiceDateTo?: Maybe<Scalars['Date']>;
  byInvoiceSentAtFrom?: Maybe<Scalars['Date']>;
  byInvoiceSentAtTo?: Maybe<Scalars['Date']>;
  byIsDescendant?: Maybe<Scalars['Boolean']>;
  byExpiredAtDateFrom?: Maybe<Scalars['Date']>;
  byExpiredAtDateTo?: Maybe<Scalars['Date']>;
  byPrivatePaymentType?: Maybe<Array<Maybe<InvoicePrivatePaymentTypeEnum>>>;
  byLocationIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type LatestAbilityLevelsType = {
  __typename?: 'LatestAbilityLevelsType';
  converse?: Maybe<AbilityLevelType>;
  talk?: Maybe<AbilityLevelType>;
  write?: Maybe<AbilityLevelType>;
  listen?: Maybe<AbilityLevelType>;
  read?: Maybe<AbilityLevelType>;
};

export enum LearnerEnrollmentTypeEnum {
  Intake = 'intake',
  Assessment = 'assessment'
}

export type LearnerInputType = {
  isActive?: Maybe<Scalars['Boolean']>;
  organizations?: Maybe<Array<Maybe<LearnerOrganizationInputType>>>;
  /** Set the organization contact-user when the editor (contextUser) is part of that organization. This field overwrites the organizations input field. */
  organizationContactUserIdBasedOnContextOrganization?: Maybe<Scalars['MongoID']>;
  /** Set the learners organization fileNumber when the editor (contextUser) is part of that organization. This field overwrites the organizations input field. */
  organizationFileNumberBasedOnContextOrganization?: Maybe<Scalars['String']>;
  paymentBy?: Maybe<UserPaymentByTypeEnum>;
  twinfieldCustomerCode?: Maybe<Scalars['String']>;
  doesNotExistInTwinfield?: Maybe<Scalars['Boolean']>;
  privatePaymentIsDUO?: Maybe<Scalars['Boolean']>;
  privatePaymentDUOHasApprovedFund?: Maybe<Scalars['Boolean']>;
  privatePaymentDUOInitialRemainingFund?: Maybe<Scalars['Float']>;
  privatePaymentAddress?: Maybe<AddressInputType>;
  typeOfLearner?: Maybe<UserTypeOfLearnerTypesEnum>;
  learningRoute?: Maybe<UserLearningRouteTypesEnum>;
  targetLevelPIP?: Maybe<UserTargetLevelTypesEnum>;
  sharePanteia?: Maybe<UserSharePanteiaTypesEnum>;
  immigratedAt?: Maybe<PrecisionDateInputType>;
  isCitizenshipEducationObliged?: Maybe<Scalars['Boolean']>;
  obligedCitizenshipEducationStartDate?: Maybe<PrecisionDateInputType>;
  obligedCitizenshipEducationInitialEndDate?: Maybe<Scalars['Date']>;
  obligedCitizenshipEducationEndDateExtensions?: Maybe<Array<Maybe<ObligedCitizenshipEducationEndDateExtensionInputType>>>;
  obligedCitizenshipEducationCompletedAt?: Maybe<Scalars['Date']>;
  socialStatus?: Maybe<Scalars['String']>;
  socialStatusExtraInfo?: Maybe<Scalars['String']>;
  familyCompositionLanguageExposure?: Maybe<Scalars['String']>;
  nativeLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  homeLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  otherLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  englishAsSupportLanguage?: Maybe<Scalars['String']>;
  workExperienceCountryOfOrigin?: Maybe<Scalars['String']>;
  workExperienceLocal?: Maybe<Scalars['String']>;
  workLiteracy?: Maybe<Scalars['String']>;
  workLiteracyLevel?: Maybe<Scalars['String']>;
  workLiteracyInfo?: Maybe<Scalars['String']>;
  currentOccupationMainLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  currentOccupationVocalInteraction?: Maybe<Scalars['String']>;
  currentOccupationVocalInteractionQuality?: Maybe<Scalars['String']>;
  currentOccupationVocalInteractionInfo?: Maybe<Scalars['String']>;
  currentOccupationStartDate?: Maybe<PrecisionDateInputType>;
  currentOccupationResponsibilities?: Maybe<Scalars['String']>;
  currentOccupationFunction?: Maybe<Scalars['String']>;
  highestEducationCountryOfOrigin?: Maybe<Scalars['String']>;
  highestEducationLocal?: Maybe<Scalars['String']>;
  highestEducationCountryOfOriginYear?: Maybe<Scalars['Int']>;
  highestEducationLocalYear?: Maybe<Scalars['Int']>;
  educationDesire?: Maybe<Scalars['String']>;
  educationExtraInfo?: Maybe<Scalars['String']>;
  computerSkill?: Maybe<Scalars['String']>;
  NT2HasAttempted?: Maybe<Scalars['Boolean']>;
  NT2Info?: Maybe<Scalars['String']>;
  NT2?: Maybe<Array<Maybe<Nt2InputType>>>;
  intakeLiteracy?: Maybe<Scalars['String']>;
  intakeLiteracyNativeLanguage?: Maybe<Scalars['String']>;
  intakeGrades?: Maybe<InflowLevelsInputType>;
  intakeReadingExamScore?: Maybe<Scalars['Int']>;
  intakeReadingExamScoreTotal?: Maybe<Scalars['Int']>;
  intakeGradesLearnability?: Maybe<Scalars['Int']>;
  intakeMotivation?: Maybe<Scalars['String']>;
  adviceModuleId?: Maybe<Scalars['MongoID']>;
  adviceModuleFilterByProjectId?: Maybe<Scalars['MongoID']>;
  adviceModuleFilterByProgramId?: Maybe<Scalars['MongoID']>;
  adviceModuleInfo?: Maybe<Scalars['String']>;
  intakeGoals?: Maybe<Array<Maybe<Scalars['String']>>>;
  intakeOtherGoal?: Maybe<Scalars['String']>;
  intakeGoalsAdditionalInfo?: Maybe<Scalars['String']>;
  preferredLocationIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  intakeResultsInfo?: Maybe<Scalars['String']>;
  howDoYouKnowTopTaal?: Maybe<Scalars['String']>;
  examDignityDate?: Maybe<Scalars['Date']>;
  enrollmentDate?: Maybe<Scalars['Date']>;
  enrollmentType?: Maybe<LearnerEnrollmentTypeEnum>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum LearnerLevelEnum {
  A0 = 'A0',
  A1Minus = 'A1Minus',
  A1 = 'A1',
  A2Minus = 'A2Minus',
  A2 = 'A2',
  B1Minus = 'B1Minus',
  B1 = 'B1',
  B2Minus = 'B2Minus',
  B2 = 'B2',
  C1Minus = 'C1Minus',
  C1 = 'C1',
  Instroom = 'Instroom',
  '1FMinus' = '_1FMinus',
  '1F' = '_1F',
  '2FMinus' = '_2FMinus',
  '2F' = '_2F',
  '3FMinus' = '_3FMinus',
  '3F' = '_3F',
  '4FMinus' = '_4FMinus',
  '4F' = '_4F'
}

export enum LearnerLevelWithAlphaEnum {
  Alpha = 'Alpha',
  A0 = 'A0',
  A1Minus = 'A1Minus',
  A1 = 'A1',
  A2Minus = 'A2Minus',
  A2 = 'A2',
  B1Minus = 'B1Minus',
  B1 = 'B1',
  B2Minus = 'B2Minus',
  B2 = 'B2',
  C1Minus = 'C1Minus',
  C1 = 'C1',
  Instroom = 'Instroom',
  '1FMinus' = '_1FMinus',
  '1F' = '_1F',
  '2FMinus' = '_2FMinus',
  '2F' = '_2F',
  '3FMinus' = '_3FMinus',
  '3F' = '_3F',
  '4FMinus' = '_4FMinus',
  '4F' = '_4F'
}

export type LearnerOrganizationInputType = {
  organizationId?: Maybe<Scalars['MongoID']>;
  organizationContactUserId?: Maybe<Scalars['MongoID']>;
  organizationFileNumber?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LegacyInflowLevelsType = {
  __typename?: 'LegacyInflowLevelsType';
  reading?: Maybe<LearnerLevelWithAlphaEnum>;
  writing?: Maybe<LearnerLevelWithAlphaEnum>;
  listening?: Maybe<LearnerLevelEnum>;
  talking?: Maybe<LearnerLevelEnum>;
  conversations?: Maybe<LearnerLevelEnum>;
};

export type LessonCompetenceCertificateFileType = {
  __typename?: 'LessonCompetenceCertificateFileType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
};

export type LessonInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  teacherUserId?: Maybe<Scalars['MongoID']>;
  groupId?: Maybe<Scalars['MongoID']>;
  date?: Maybe<Scalars['Date']>;
  locationId?: Maybe<Scalars['MongoID']>;
  roomId?: Maybe<Scalars['MongoID']>;
  specialActivity?: Maybe<LessonSpecialActivityEnum>;
  plannedAbilityExams?: Maybe<Array<PlannedAbilityExamInputType>>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum LessonReasonOfAbsenceEnum {
  OtherIntegrationActivity = 'OTHER_INTEGRATION_ACTIVITY',
  Exam = 'EXAM',
  PrivateReasons = 'PRIVATE_REASONS',
  TransportIssues = 'TRANSPORT_ISSUES',
  Vacation = 'VACATION',
  WorkStudy = 'WORK_STUDY',
  Sick = 'SICK',
  Other = 'OTHER'
}

export enum LessonSpecialActivityEnum {
  PortfolioDeadline = 'portfolioDeadline',
  ReportMoment = 'reportMoment',
  Exam = 'exam',
  Assessment = 'assessment'
}

export type LessonType = {
  __typename?: 'LessonType';
  _id: Scalars['MongoID'];
  date: Scalars['Date'];
  order: Scalars['Int'];
  areAllAttendancesSet: Scalars['Boolean'];
  duration?: Maybe<Scalars['Int']>;
  group?: Maybe<GroupType>;
  teacherUserId?: Maybe<Scalars['MongoID']>;
  teacherUser?: Maybe<UserType>;
  location?: Maybe<LocationType>;
  room?: Maybe<RoomType>;
  lessonUsers?: Maybe<Array<Maybe<LessonUserType>>>;
  hasResults?: Maybe<Scalars['Boolean']>;
  lessonUsersCount?: Maybe<Scalars['Int']>;
  specialActivity?: Maybe<LessonSpecialActivityEnum>;
  plannedAbilityExams?: Maybe<Array<PlannedAbilityExamType>>;
};


export type LessonTypeLessonUsersArgs = {
  filterByLearnerUserIds?: Maybe<Array<Scalars['MongoID']>>;
  filterByAttendance?: Maybe<Scalars['String']>;
};


export type LessonTypeLessonUsersCountArgs = {
  filterByAttendance?: Maybe<Scalars['String']>;
};

export type LessonUserInputType = {
  learnerUserId: Scalars['MongoID'];
  lessonId: Scalars['MongoID'];
  attendance?: Maybe<Scalars['String']>;
  reasonOfAbsence?: Maybe<LessonReasonOfAbsenceEnum>;
  attendanceNote?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LessonUserType = {
  __typename?: 'LessonUserType';
  _id: Scalars['MongoID'];
  attendance?: Maybe<Scalars['String']>;
  reasonOfAbsence?: Maybe<LessonReasonOfAbsenceEnum>;
  attendanceNote?: Maybe<Scalars['String']>;
  attendanceSetByUser?: Maybe<UserType>;
  attendanceSetAt?: Maybe<Scalars['Date']>;
  learnerUserId?: Maybe<Scalars['MongoID']>;
  learnerUser?: Maybe<UserType>;
  isRemovedMember?: Maybe<Scalars['Boolean']>;
  lessonDate?: Maybe<Scalars['Date']>;
  lesson?: Maybe<LessonType>;
};

export type LocalizedExtraLearnerInformationInputType = {
  nl?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
};

export type LocalizedExtraLearnerInformationType = {
  __typename?: 'LocalizedExtraLearnerInformationType';
  nl?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
};

export type LocationInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInputType>;
  organizationName?: Maybe<Scalars['String']>;
  contactPersonName?: Maybe<Scalars['String']>;
  rentalDateFrom?: Maybe<Scalars['Date']>;
  rentalDateTo?: Maybe<Scalars['Date']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LocationPropertyInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LocationPropertyType = {
  __typename?: 'LocationPropertyType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
};

export type LocationType = {
  __typename?: 'LocationType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<AddressType>;
  organizationName?: Maybe<Scalars['String']>;
  contactPersonName?: Maybe<Scalars['String']>;
  rentalDateFrom?: Maybe<Scalars['Date']>;
  rentalDateTo?: Maybe<Scalars['Date']>;
  rooms?: Maybe<Array<Maybe<RoomType>>>;
};


export type LocationTypeRoomsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};

export type ModuleExamAbilitiesInputType = {
  converse?: Maybe<Scalars['Boolean']>;
  listen?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  talk?: Maybe<Scalars['Boolean']>;
  write?: Maybe<Scalars['Boolean']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ModuleExamAbilitiesType = {
  __typename?: 'ModuleExamAbilitiesType';
  converse?: Maybe<Scalars['Boolean']>;
  listen?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  talk?: Maybe<Scalars['Boolean']>;
  write?: Maybe<Scalars['Boolean']>;
};

export type ModuleExamInputType = {
  examId?: Maybe<Scalars['MongoID']>;
  abilities?: Maybe<ModuleExamAbilitiesInputType>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ModuleExamType = {
  __typename?: 'ModuleExamType';
  examId?: Maybe<Scalars['MongoID']>;
  abilities?: Maybe<ModuleExamAbilitiesType>;
  _id?: Maybe<Scalars['MongoID']>;
  exam?: Maybe<ExamType>;
};

export type ModuleInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  /** Alfabetisering */
  isLiteracyCourse?: Maybe<Scalars['Boolean']>;
  /** Type project */
  projectType?: Maybe<ProjectTypeEnum>;
  /** Type inburgering */
  integrationType?: Maybe<IntegrationTypeEnum>;
  /** Inburgeringsexameen */
  isFinalExamTraining?: Maybe<Scalars['Boolean']>;
  /** Bevat eindgesprek */
  hasFinalReview?: Maybe<Scalars['Boolean']>;
  startLevel?: Maybe<LearnerLevelWithAlphaEnum>;
  endLevel?: Maybe<LearnerLevelWithAlphaEnum>;
  isActive?: Maybe<Scalars['Boolean']>;
  lessonDuration?: Maybe<Scalars['Int']>;
  amountOfWeeklyLessons?: Maybe<Scalars['Int']>;
  amountOfTotalLessons?: Maybe<Scalars['Int']>;
  capacity?: Maybe<Scalars['Int']>;
  courseMaterialIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  locationPropertyIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exams?: Maybe<Array<ModuleExamInputType>>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ModuleType = {
  __typename?: 'ModuleType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
  /** Alfabetisering */
  isLiteracyCourse?: Maybe<Scalars['Boolean']>;
  /** Type project */
  projectType?: Maybe<ProjectTypeEnum>;
  /** Type inburgering */
  integrationType?: Maybe<IntegrationTypeEnum>;
  isIntegrationCourse?: Maybe<Scalars['Boolean']>;
  /** Inburgeringsexameen */
  isFinalExamTraining?: Maybe<Scalars['Boolean']>;
  /** Bevat eindgesprek */
  hasFinalReview?: Maybe<Scalars['Boolean']>;
  startLevel?: Maybe<LearnerLevelEnum>;
  endLevel?: Maybe<LearnerLevelEnum>;
  isActive?: Maybe<Scalars['Boolean']>;
  lessonDuration?: Maybe<Scalars['Int']>;
  amountOfWeeklyLessons?: Maybe<Scalars['Int']>;
  amountOfTotalLessons?: Maybe<Scalars['Int']>;
  capacity?: Maybe<Scalars['Int']>;
  courseMaterials?: Maybe<Array<Maybe<CourseMaterialType>>>;
  exams?: Maybe<Array<Maybe<ModuleExamType>>>;
  locationProperties?: Maybe<Array<Maybe<LocationPropertyType>>>;
  isUsedInNonConceptGroups?: Maybe<Scalars['Boolean']>;
  isTemplateModule?: Maybe<Scalars['Boolean']>;
  usedByProgram?: Maybe<Scalars['Boolean']>;
};

export type ModulesFiltersInputType = {
  filterByProjectType?: Maybe<ProjectTypeEnum>;
  filterByIntegrationType?: Maybe<IntegrationTypeEnum>;
};


export type Nt2CertificateFileType = {
  __typename?: 'NT2CertificateFileType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
};

export type Nt2InputType = {
  year?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['String']>;
  level?: Maybe<LearnerLevelWithAlphaEnum>;
  organizationName?: Maybe<Scalars['String']>;
  courseMaterials?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Nt2Type = {
  __typename?: 'NT2Type';
  year?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['String']>;
  level?: Maybe<LearnerLevelWithAlphaEnum>;
  organizationName?: Maybe<Scalars['String']>;
  courseMaterials?: Maybe<Scalars['String']>;
};

export type NoteInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  type?: Maybe<NoteTypeEnum>;
  text?: Maybe<Scalars['String']>;
  typeData?: Maybe<NoteTypeDataInputType>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NoteType = {
  __typename?: 'NoteType';
  _id: Scalars['MongoID'];
  text?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUser?: Maybe<UserType>;
};

export type NoteTypeDataInputType = {
  groupId?: Maybe<Scalars['MongoID']>;
};

export enum NoteTypeEnum {
  GroupNote = 'groupNote'
}

export type NotesFilterInputType = {
  filterByType?: Maybe<NoteTypeEnum>;
  filterByTypeData?: Maybe<NotesFilterTypeDataInputType>;
};

export type NotesFilterTypeDataInputType = {
  groupId?: Maybe<Scalars['MongoID']>;
};

export type ObligedCitizenshipEducationEndDateExtensionInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['UploadedFile']>;
};

export type ObligedCitizenshipEducationEndDateExtensiontype = {
  __typename?: 'ObligedCitizenshipEducationEndDateExtensiontype';
  _id?: Maybe<Scalars['MongoID']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  document?: Maybe<UserDocumentType>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUser?: Maybe<UserType>;
};

export enum OpenInvoiceStatusEnum {
  WaitingForPayment = 'WaitingForPayment',
  NeedsSignatureForDuo = 'NeedsSignatureForDUO',
  WaitingForDuoExportBatch = 'WaitingForDUOExportBatch',
  WaitingForDuoApproval = 'WaitingForDUOApproval',
  RejectedByDuo = 'RejectedByDUO'
}

export type OrganizationContactInputType = {
  organizationId?: Maybe<Scalars['MongoID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationDocumentInputType = {
  file?: Maybe<Scalars['UploadedFile']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationDocumentType = {
  __typename?: 'OrganizationDocumentType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
  isPDF: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  createdByUser?: Maybe<UserType>;
};

export type OrganizationInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInputType>;
  billingAddress?: Maybe<AddressInputType>;
  invoiceEmailRecipients?: Maybe<Array<Maybe<OrganizationInvoiceEmailRecipientInputType>>>;
  phoneNumber?: Maybe<PhoneNumberInputType>;
  contactName?: Maybe<Scalars['String']>;
  organizationCode?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['MongoID']>;
  notes?: Maybe<Scalars['String']>;
  twinfieldCustomerCode?: Maybe<Scalars['String']>;
  requestNewTwinfieldCustomerCode?: Maybe<Scalars['Boolean']>;
  vatExempt?: Maybe<Scalars['Boolean']>;
  invoiceTexts?: Maybe<Array<Maybe<Scalars['String']>>>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationInvoiceEmailRecipientInputType = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationInvoiceEmailRecipientType = {
  __typename?: 'OrganizationInvoiceEmailRecipientType';
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
  invoiceEmailRecipients?: Maybe<Array<Maybe<OrganizationInvoiceEmailRecipientType>>>;
  address?: Maybe<AddressType>;
  billingAddress?: Maybe<AddressType>;
  phoneNumber?: Maybe<PhoneNumberType>;
  contactName?: Maybe<Scalars['String']>;
  contactUsers?: Maybe<Array<Maybe<UserType>>>;
  organizationCode?: Maybe<Scalars['String']>;
  canTwinfieldCustomerCodeChange?: Maybe<Scalars['Boolean']>;
  vatExempt?: Maybe<Scalars['Boolean']>;
  twinfieldCustomer?: Maybe<TwinfieldCustomerType>;
  project?: Maybe<ProjectType>;
  amountOfLearners?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  invoiceTexts?: Maybe<Array<Maybe<Scalars['String']>>>;
  groups?: Maybe<Array<GroupType>>;
  timelineEvents?: Maybe<Array<TimelineEventType>>;
  documents?: Maybe<Array<Maybe<OrganizationDocumentType>>>;
};


export type OrganizationTypeGroupsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type OrganizationTypeTimelineEventsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type OrganizationTypeDocumentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};

export type PaginatedInvoiceType = {
  __typename?: 'PaginatedInvoiceType';
  nodes?: Maybe<Array<InvoiceType>>;
  count: Scalars['Int'];
  totalCount: Scalars['Int'];
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
};

export type PaginatedUserType = {
  __typename?: 'PaginatedUserType';
  nodes?: Maybe<Array<UserType>>;
  count: Scalars['Int'];
  totalCount: Scalars['Int'];
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
};

export type PhoneNumberInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PhoneNumberType = {
  __typename?: 'PhoneNumberType';
  _id: Scalars['MongoID'];
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  internationalPhoneNumber?: Maybe<Scalars['String']>;
};

export type PlannedAbilityExamInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  examId?: Maybe<Scalars['MongoID']>;
  examAbility?: Maybe<ExamAbiltiesTypeEnum>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PlannedAbilityExamType = {
  __typename?: 'PlannedAbilityExamType';
  _id?: Maybe<Scalars['MongoID']>;
  examId?: Maybe<Scalars['MongoID']>;
  exam?: Maybe<ExamType>;
  lesson?: Maybe<LessonType>;
  group?: Maybe<GroupType>;
  examAbility?: Maybe<ExamAbiltiesTypeEnum>;
  results?: Maybe<Array<Maybe<ResultType>>>;
  resultsCompleted?: Maybe<Scalars['Boolean']>;
};

export enum PrecisionDateEnum {
  Year = 'YEAR',
  Month = 'MONTH',
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Second = 'SECOND'
}

export type PrecisionDateInputType = {
  date: Scalars['Date'];
  precision: PrecisionDateEnum;
};

export type PrecisionDateType = {
  __typename?: 'PrecisionDateType';
  date: Scalars['Date'];
  precision: PrecisionDateEnum;
};

export type ProcessItemType = AdviceReportType | GroupType | FinalExamType;

export type ProcessType = {
  __typename?: 'ProcessType';
  type?: Maybe<ProcessTypesEnum>;
  date?: Maybe<Scalars['Date']>;
  data?: Maybe<ProcessItemType>;
  _id: Scalars['MongoID'];
};

export enum ProcessTypesEnum {
  Group = 'Group',
  AdviceReport = 'AdviceReport',
  FinalExam = 'FinalExam'
}

export type ProfileInputType = {
  initials?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  surName?: Maybe<Scalars['String']>;
  surNamePrefix?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  alternativeEmail?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  countryOfBirth?: Maybe<Scalars['String']>;
  cityOfBirth?: Maybe<Scalars['String']>;
  countriesOfNationality?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasNoCountryOfNationality?: Maybe<Scalars['Boolean']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInputType>;
  phoneNumbers?: Maybe<Array<Maybe<PhoneNumberInputType>>>;
  visitAddress?: Maybe<AddressInputType>;
  notes?: Maybe<Scalars['String']>;
  bsn?: Maybe<Scalars['String']>;
  bsnVerified?: Maybe<Scalars['Boolean']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PrognosisYearsRowDataType = {
  __typename?: 'PrognosisYearsRowDataType';
  index?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
};

export type PrognosisYearsRowType = {
  __typename?: 'PrognosisYearsRowType';
  twinfieldArticleCode: Scalars['String'];
  months?: Maybe<Array<Maybe<PrognosisYearsRowDataType>>>;
  amount?: Maybe<Scalars['Float']>;
};

export type PrognosisYearsType = {
  __typename?: 'PrognosisYearsType';
  year?: Maybe<Scalars['Int']>;
  articles?: Maybe<Array<Maybe<PrognosisYearsRowType>>>;
  months?: Maybe<Array<Maybe<PrognosisYearsRowDataType>>>;
  amount?: Maybe<Scalars['Float']>;
};

export type ProgramInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  moduleIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** Type project */
  projectType?: Maybe<ProjectTypeEnum>;
  /** Type inburgering */
  integrationType?: Maybe<IntegrationTypeEnum>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProgramType = {
  __typename?: 'ProgramType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
  /** Type project */
  projectType?: Maybe<ProjectTypeEnum>;
  /** Type inburgering */
  integrationType?: Maybe<IntegrationTypeEnum>;
  modules?: Maybe<Array<Maybe<ModuleType>>>;
  usedByProject?: Maybe<Scalars['Boolean']>;
};

export type ProgramsFiltersInputType = {
  filterByProjectType?: Maybe<ProjectTypeEnum>;
  filterByIntegrationType?: Maybe<IntegrationTypeEnum>;
};

export type ProjectArticleCodesInputType = {
  paymentType?: Maybe<ProjectArticleCodesPaymentTypeEnum>;
  articleCodeType?: Maybe<ArticleCodesTypeEnum>;
  articleCode?: Maybe<Scalars['String']>;
};

export enum ProjectArticleCodesPaymentTypeEnum {
  PrivateDuo = 'privateDuo',
  PrivateSelf = 'privateSelf',
  Organization = 'organization'
}

export type ProjectArticleCodesType = {
  __typename?: 'ProjectArticleCodesType';
  paymentType?: Maybe<ProjectArticleCodesPaymentTypeEnum>;
  articleCodeType?: Maybe<ArticleCodesTypeEnum>;
  articleCode?: Maybe<Scalars['String']>;
};

export enum ProjectChargeForLessonAttendanceTypeEnum {
  Any = 'Any',
  Present = 'Present',
  PresentAndAbsent = 'PresentAndAbsent'
}

export enum ProjectChargeForLessonsTypeEnum {
  OnlyEnrolledLessons = 'OnlyEnrolledLessons',
  AllLessons = 'AllLessons'
}

export type ProjectInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  articleCodes?: Maybe<Array<Maybe<ProjectArticleCodesInputType>>>;
  lessonInviteReplyTo?: Maybe<Scalars['String']>;
  /** Type project */
  projectType?: Maybe<ProjectTypeEnum>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ProjectInvoiceGranularityEnum {
  Group = 'Group',
  Learner = 'Learner'
}

export enum ProjectInvoiceTimingEnum {
  Periodical = 'Periodical',
  Variable = 'Variable'
}

export type ProjectInvoicingSettingFixedPriceSettingsInputType = {
  amount?: Maybe<Scalars['Float']>;
};

export type ProjectInvoicingSettingFixedPriceSettingsType = {
  __typename?: 'ProjectInvoicingSettingFixedPriceSettingsType';
  amount?: Maybe<Scalars['Float']>;
};

export type ProjectInvoicingSettingInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  programIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  projectId?: Maybe<Scalars['MongoID']>;
  invoiceGranularity?: Maybe<ProjectInvoiceGranularityEnum>;
  priceType?: Maybe<ProjectPriceTypeEnum>;
  invoiceTiming?: Maybe<ProjectInvoiceTimingEnum>;
  fixedPriceSettings?: Maybe<ProjectInvoicingSettingFixedPriceSettingsInputType>;
  variablePriceSettings?: Maybe<ProjectInvoicingSettingVariablePriceSettingsInputType>;
  periodicalTimingSettings?: Maybe<ProjectInvoicingSettingPeriodicalTimingSettingsInputType>;
  variableTimingSettings?: Maybe<ProjectInvoicingSettingVariableTimingSettingsInputType>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProjectInvoicingSettingPeriodicalTimingSettingsInputType = {
  period?: Maybe<ProjectPeriodicalTimingPeriodEnum>;
  chargeForLessonAttendanceType?: Maybe<ProjectChargeForLessonAttendanceTypeEnum>;
  chargeForLessonReasonOfAbsences?: Maybe<Array<Maybe<ChargeForLessonReasonOfAbsenceEnum>>>;
};

export type ProjectInvoicingSettingPeriodicalTimingSettingsType = {
  __typename?: 'ProjectInvoicingSettingPeriodicalTimingSettingsType';
  period?: Maybe<ProjectPeriodicalTimingPeriodEnum>;
  chargeForLessonAttendanceType?: Maybe<ProjectChargeForLessonAttendanceTypeEnum>;
  chargeForLessonReasonOfAbsences?: Maybe<Array<Maybe<ChargeForLessonReasonOfAbsenceEnum>>>;
};

export type ProjectInvoicingSettingType = {
  __typename?: 'ProjectInvoicingSettingType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<Maybe<ProgramType>>>;
  project?: Maybe<Array<Maybe<ProgramType>>>;
  invoiceGranularity?: Maybe<ProjectInvoiceGranularityEnum>;
  priceType?: Maybe<ProjectPriceTypeEnum>;
  invoiceTiming?: Maybe<ProjectInvoiceTimingEnum>;
  fixedPriceSettings?: Maybe<ProjectInvoicingSettingFixedPriceSettingsType>;
  variablePriceSettings?: Maybe<ProjectInvoicingSettingVariablePriceSettingsType>;
  periodicalTimingSettings?: Maybe<ProjectInvoicingSettingPeriodicalTimingSettingsType>;
  variableTimingSettings?: Maybe<ProjectInvoicingSettingVariableTimingSettingsType>;
};

export type ProjectInvoicingSettingVariablePriceSettingsAmountRangeInputType = {
  to?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
};

export type ProjectInvoicingSettingVariablePriceSettingsAmountRangeType = {
  __typename?: 'ProjectInvoicingSettingVariablePriceSettingsAmountRangeType';
  to?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
};

export type ProjectInvoicingSettingVariablePriceSettingsInputType = {
  variableBy?: Maybe<ProjectVariablePriceVariableByEnum>;
  amountRanges?: Maybe<Array<Maybe<ProjectInvoicingSettingVariablePriceSettingsAmountRangeInputType>>>;
};

export type ProjectInvoicingSettingVariablePriceSettingsType = {
  __typename?: 'ProjectInvoicingSettingVariablePriceSettingsType';
  variableBy?: Maybe<ProjectVariablePriceVariableByEnum>;
  amountRanges?: Maybe<Array<Maybe<ProjectInvoicingSettingVariablePriceSettingsAmountRangeType>>>;
};

export type ProjectInvoicingSettingVariableTimingConditionSettingsInputType = {
  condition?: Maybe<ProjectPeriodConditionEnum>;
  isChecked?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type ProjectInvoicingSettingVariableTimingConditionSettingsType = {
  __typename?: 'ProjectInvoicingSettingVariableTimingConditionSettingsType';
  condition?: Maybe<ProjectPeriodConditionEnum>;
  isChecked?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type ProjectInvoicingSettingVariableTimingPeriodInputType = {
  percentageOfPassedLessons?: Maybe<Scalars['Float']>;
  percentageOfPrice?: Maybe<Scalars['Float']>;
  conditionSettings?: Maybe<Array<Maybe<ProjectInvoicingSettingVariableTimingConditionSettingsInputType>>>;
};

export type ProjectInvoicingSettingVariableTimingPeriodType = {
  __typename?: 'ProjectInvoicingSettingVariableTimingPeriodType';
  percentageOfPassedLessons?: Maybe<Scalars['Float']>;
  percentageOfPrice?: Maybe<Scalars['Float']>;
  conditionSettings?: Maybe<Array<Maybe<ProjectInvoicingSettingVariableTimingConditionSettingsType>>>;
};

export type ProjectInvoicingSettingVariableTimingSettingsInputType = {
  chargeForLessonsType?: Maybe<ProjectChargeForLessonsTypeEnum>;
  periods?: Maybe<Array<Maybe<ProjectInvoicingSettingVariableTimingPeriodInputType>>>;
};

export type ProjectInvoicingSettingVariableTimingSettingsType = {
  __typename?: 'ProjectInvoicingSettingVariableTimingSettingsType';
  chargeForLessonsType?: Maybe<ProjectChargeForLessonsTypeEnum>;
  periods?: Maybe<Array<Maybe<ProjectInvoicingSettingVariableTimingPeriodType>>>;
};

export enum ProjectPeriodConditionEnum {
  ReceivedConfirmationOfMunicipality = 'ReceivedConfirmationOfMunicipality',
  AttendancePercentage = 'AttendancePercentage',
  ParticipationInExam = 'ParticipationInExam',
  PassedExam = 'PassedExam',
  DateOfDisenrollment = 'DateOfDisenrollment',
  Other = 'Other'
}

export enum ProjectPeriodicalTimingPeriodEnum {
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly'
}

export enum ProjectPriceTypeEnum {
  Fixed = 'Fixed',
  Variable = 'Variable'
}

export type ProjectType = {
  __typename?: 'ProjectType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
  programs?: Maybe<Array<Maybe<ProgramType>>>;
  articleCodes?: Maybe<Array<Maybe<ProjectArticleCodesType>>>;
  /** Type project */
  projectType?: Maybe<ProjectTypeEnum>;
  lessonInviteReplyTo?: Maybe<Scalars['String']>;
  projectInvoicingSettings?: Maybe<Array<Maybe<ProjectInvoicingSettingType>>>;
};


export type ProjectTypeProgramsArgs = {
  filterByAvailableForNewInvoicingSetting?: Maybe<Scalars['Boolean']>;
  filterByAvailableForExistingInvoicingSettingId?: Maybe<Scalars['MongoID']>;
};


export type ProjectTypeProjectInvoicingSettingsArgs = {
  byId?: Maybe<Scalars['MongoID']>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};

export enum ProjectTypeEnum {
  Integration = 'Integration',
  Organization = 'Organization',
  Other = 'Other'
}

export enum ProjectVariablePriceVariableByEnum {
  Hour = 'Hour',
  Learner = 'Learner'
}

export type RemovedGroupUserInputType = {
  role?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['MongoID']>;
  status?: Maybe<Scalars['String']>;
  adviceModuleId?: Maybe<Scalars['MongoID']>;
  date?: Maybe<Scalars['Date']>;
  removedReason?: Maybe<GroupUserRemovedReasonEnum>;
  removedInfo?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ResultDocumentFileType = {
  __typename?: 'ResultDocumentFileType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
};

export type ResultDocumentInputType = {
  existingFileIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  files?: Maybe<Array<Maybe<Scalars['UploadedFile']>>>;
};

export type ResultInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  plannedAbilityExamId?: Maybe<Scalars['MongoID']>;
  learnerUserId?: Maybe<Scalars['MongoID']>;
  result?: Maybe<ResultResultInputType>;
  documentFiles?: Maybe<Array<Maybe<ResultDocumentInputType>>>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ResultResultInputType = {
  passedNotPassed?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  level?: Maybe<LearnerLevelWithAlphaEnum>;
  absenceExplanation?: Maybe<Scalars['String']>;
  status?: Maybe<ResultStatusEnum>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ResultResultType = {
  __typename?: 'ResultResultType';
  passedNotPassed?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  level?: Maybe<LearnerLevelWithAlphaEnum>;
  status?: Maybe<ResultStatusEnum>;
  absenceExplanation?: Maybe<Scalars['String']>;
};

export enum ResultStatusEnum {
  NoShow = 'no_show',
  Exempt = 'exempt',
  Participated = 'participated'
}

export type ResultType = {
  __typename?: 'ResultType';
  _id: Scalars['MongoID'];
  plannedAbilityExamId?: Maybe<Scalars['MongoID']>;
  plannedAbilityExam?: Maybe<PlannedAbilityExamType>;
  learnerUserId?: Maybe<Scalars['MongoID']>;
  learnerUser?: Maybe<UserType>;
  examAbility?: Maybe<Scalars['String']>;
  examId?: Maybe<Scalars['MongoID']>;
  exam?: Maybe<ExamType>;
  result?: Maybe<ResultResultType>;
  files?: Maybe<Array<Maybe<ResultDocumentFileType>>>;
};

export type RevenueYearArticleType = {
  __typename?: 'RevenueYearArticleType';
  twinfieldArticleCode: Scalars['String'];
  months?: Maybe<Array<Maybe<RevenueYearMonthType>>>;
  amount?: Maybe<Scalars['Float']>;
};

export type RevenueYearMonthType = {
  __typename?: 'RevenueYearMonthType';
  index?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
};

export type RevenueYearType = {
  __typename?: 'RevenueYearType';
  year?: Maybe<Scalars['Int']>;
  articles?: Maybe<Array<Maybe<RevenueYearArticleType>>>;
  months?: Maybe<Array<Maybe<RevenueYearMonthType>>>;
  amount?: Maybe<Scalars['Float']>;
};

export type RoomInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['MongoID']>;
  capacity?: Maybe<Scalars['Int']>;
  locationPropertyIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RoomType = {
  __typename?: 'RoomType';
  _id: Scalars['MongoID'];
  name?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  locationProperties?: Maybe<Array<Maybe<LocationPropertyType>>>;
  availabilityCalendarEvents?: Maybe<Array<CalendarEventType>>;
  lessons?: Maybe<Array<LessonType>>;
  location?: Maybe<LocationType>;
  inflowMoments?: Maybe<Array<InflowMomentType>>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  calendarEvents_create?: Maybe<CalendarEventType>;
  calendarEvents_edit?: Maybe<CalendarEventType>;
  calendarEvents_delete?: Maybe<CalendarEventType>;
  courseMaterials_create?: Maybe<CourseMaterialType>;
  courseMaterials_edit?: Maybe<CourseMaterialType>;
  courseMaterials_delete?: Maybe<CourseMaterialType>;
  exams_create?: Maybe<ExamType>;
  exams_edit?: Maybe<ExamType>;
  exams_delete?: Maybe<ExamType>;
  fileSessions_create?: Maybe<FileSessionType>;
  groups_create?: Maybe<GroupType>;
  groups_edit?: Maybe<GroupType>;
  groups_delete?: Maybe<GroupType>;
  groups_changeConceptUsers?: Maybe<GroupType>;
  groups_enrollUser?: Maybe<GroupType>;
  groups_disenrollUser?: Maybe<GroupType>;
  groups_undoEnrollment?: Maybe<GroupType>;
  groups_undoDisenrollment?: Maybe<GroupType>;
  groups_createUsersExportFile?: Maybe<ExportResponseType>;
  groups_validate?: Maybe<ValidationResponseType>;
  groups_makeFinal?: Maybe<GroupType>;
  groups_end?: Maybe<GroupType>;
  groups_addDocuments?: Maybe<GroupType>;
  groups_removeDocuments?: Maybe<GroupType>;
  instructionVideos_create?: Maybe<InstructionVideoType>;
  instructionVideos_edit?: Maybe<InstructionVideoType>;
  instructionVideos_delete?: Maybe<InstructionVideoType>;
  invoices_createDebit?: Maybe<InvoiceType>;
  invoices_deleteDebit?: Maybe<InvoiceType>;
  invoices_createGroupParticipationCreditInvoice?: Maybe<InvoiceType>;
  invoices_createFinalExamCreditInvoice?: Maybe<InvoiceType>;
  invoices_createLessonMaterialsCreditInvoice?: Maybe<InvoiceType>;
  invoices_deleteCredit?: Maybe<InvoiceType>;
  invoices_makeFinal?: Maybe<InvoiceType>;
  invoices_credit_reject?: Maybe<InvoiceType>;
  invoices_generatePdf?: Maybe<ExportResponseType>;
  invoices_uploadSignatureForDuo?: Maybe<InvoiceType>;
  invoices_cancel?: Maybe<InvoiceType>;
  invoices_createExportFile?: Maybe<ExportResponseType>;
  lessons_create?: Maybe<LessonType>;
  lessons_edit?: Maybe<LessonType>;
  lessons_delete?: Maybe<LessonType>;
  lessons_setPresence?: Maybe<LessonType>;
  locationProperties_create?: Maybe<LocationPropertyType>;
  locationProperties_edit?: Maybe<LocationPropertyType>;
  locationProperties_delete?: Maybe<LocationPropertyType>;
  locations_create?: Maybe<LocationType>;
  locations_edit?: Maybe<LocationType>;
  locations_delete?: Maybe<LocationType>;
  modules_create?: Maybe<ModuleType>;
  modules_edit?: Maybe<ModuleType>;
  modules_delete?: Maybe<ModuleType>;
  organizations_create?: Maybe<OrganizationType>;
  organizations_edit?: Maybe<OrganizationType>;
  organizations_delete?: Maybe<OrganizationType>;
  organizations_addDocuments?: Maybe<OrganizationType>;
  organizations_removeDocuments?: Maybe<OrganizationType>;
  programs_create?: Maybe<ProgramType>;
  programs_edit?: Maybe<ProgramType>;
  programs_delete?: Maybe<ProgramType>;
  projects_create?: Maybe<ProjectType>;
  projects_edit?: Maybe<ProjectType>;
  projects_delete?: Maybe<ProjectType>;
  projectInvoicingSettings_create?: Maybe<ProjectInvoicingSettingType>;
  projectInvoicingSettings_edit?: Maybe<ProjectInvoicingSettingType>;
  projectInvoicingSettings_delete?: Maybe<Scalars['Boolean']>;
  rooms_create?: Maybe<RoomType>;
  rooms_edit?: Maybe<RoomType>;
  rooms_delete?: Maybe<RoomType>;
  sessions_login?: Maybe<SessionType>;
  sessions_renew?: Maybe<SessionType>;
  sessions_logout?: Maybe<Scalars['Boolean']>;
  timelineEvents_create?: Maybe<TimelineEventType>;
  timelineEvents_edit?: Maybe<TimelineEventType>;
  timelineEvents_delete?: Maybe<TimelineEventType>;
  users_create?: Maybe<UserType>;
  users_edit?: Maybe<UserType>;
  users_delete?: Maybe<UserType>;
  users_addDocuments?: Maybe<UserType>;
  users_removeDocuments?: Maybe<UserType>;
  users_forgot_password?: Maybe<Scalars['Boolean']>;
  users_change_password?: Maybe<UserType>;
  users_sendInvite?: Maybe<UserType>;
  users_resetCognitoTotpMfa?: Maybe<UserType>;
  users_changeStatus?: Maybe<UserType>;
  users_setMfaFields?: Maybe<UserType>;
  users_changeEmail?: Maybe<UserType>;
  users_setCognitoSmsMfa?: Maybe<UserType>;
  users_createExportFile?: Maybe<ExportResponseType>;
  users_importFromEdisaFile?: Maybe<EdisaImportResponseType>;
  users_resetIntake?: Maybe<UserType>;
  users_createLessonAttendanceFile?: Maybe<ExportResponseType>;
  users_createLessonTimetableFile?: Maybe<ExportResponseType>;
  users_createLessonInviteFile?: Maybe<ExportResponseType>;
  users_sendLessonInvites?: Maybe<Scalars['Boolean']>;
  users_sendAttendanceReminder?: Maybe<UserType>;
  users_accept_organizationContactAgreement?: Maybe<Scalars['Boolean']>;
  users_activateInvoicingByDUO?: Maybe<UserType>;
  users_updateInternalDUODeclarations?: Maybe<UserType>;
  users_selectContextUserAsIntaker?: Maybe<UserType>;
  users_updateEnrollmentData?: Maybe<UserType>;
  users_import?: Maybe<Array<Maybe<UserImportDuplicateReturnType>>>;
  users_inflowOnHoldSet?: Maybe<Array<Maybe<UserType>>>;
  users_inflowOnHoldRemove?: Maybe<Array<Maybe<UserType>>>;
  employee_to_external_teacher?: Maybe<UserType>;
  external_teacher_to_employee?: Maybe<UserType>;
  DUODeclarations_create?: Maybe<DuoDeclarationType>;
  DUODeclarations_edit?: Maybe<DuoDeclarationType>;
  DUODeclarations_delete?: Maybe<DuoDeclarationType>;
  notes_create?: Maybe<NoteType>;
  notes_edit?: Maybe<NoteType>;
  notes_delete?: Maybe<NoteType>;
  DUOInvoices_generate?: Maybe<DuoInvoiceType>;
  DUOResponse_addDocument?: Maybe<DuoResponseType>;
  results_create?: Maybe<ResultType>;
  results_edit?: Maybe<ResultType>;
  results_delete?: Maybe<ResultType>;
  result_enterMultiple?: Maybe<Scalars['Boolean']>;
  finalExams_insertMultiple?: Maybe<FinalExamInsertReturnType>;
  finalExams_cancel?: Maybe<Scalars['Boolean']>;
  finalExams_update?: Maybe<Scalars['Boolean']>;
  finalExams_uploadDUOResults?: Maybe<Scalars['Boolean']>;
  DUOLeanerReports_generate?: Maybe<DuoLearnerReportType>;
  userLearnerHours_create?: Maybe<UserLearnerHourType>;
  userLearnerHours_edit?: Maybe<UserLearnerHourType>;
  userLearnerHours_delete?: Maybe<UserLearnerHourType>;
  exports_generate_citizenship?: Maybe<ExportType>;
  exports_generate_outflow?: Maybe<ExportType>;
  exports_audit_generate_teacher?: Maybe<ExportType>;
  exports_audit_generate_finalExam_attempts?: Maybe<ExportType>;
  exports_enrollment_contracts_duo_bow?: Maybe<ExportType>;
  exports_participated_hours_duo?: Maybe<ExportType>;
  conteptGroups_createUsersExportFile?: Maybe<ExportType>;
  DUOFinalExamResultErrors_resolve?: Maybe<Scalars['Boolean']>;
  inflowModules_create?: Maybe<InflowModuleType>;
  inflowModules_edit?: Maybe<InflowModuleType>;
  inflowModules_delete?: Maybe<InflowModuleType>;
  inflowMoments_create?: Maybe<InflowMomentType>;
  inflowMoments_edit?: Maybe<InflowMomentType>;
  inflowMoments_delete?: Maybe<InflowMomentType>;
  inflowMoments_makeFinal?: Maybe<InflowMomentType>;
  inflowMoment_sendInvites?: Maybe<ExportResponseType>;
  inflowMomentTimeslots_add?: Maybe<InflowMomentType>;
  inflowMomentTimeslots_changeUsers?: Maybe<InflowMomentTimeslotType>;
  inflowMomentTimeslots_multiple_changeUsers?: Maybe<Scalars['Boolean']>;
  adviceReports_create?: Maybe<AdviceReportType>;
  adviceReports_edit?: Maybe<AdviceReportType>;
  adviceReports_delete?: Maybe<AdviceReportType>;
  adviceReport_revoke?: Maybe<AdviceReportType>;
  adviceReport_release?: Maybe<AdviceReportType>;
  adviceReports_generateFile?: Maybe<ExportResponseType>;
  contracts_terminate?: Maybe<ContractType>;
  contracts_makeFinal?: Maybe<ContractType>;
  contracts_uploadSignature?: Maybe<ContractType>;
  contracts_refreshForUser?: Maybe<Scalars['Boolean']>;
  inflowMomentTimeslotUser_setNoShowStatus?: Maybe<InflowMomentTimeslotUserType>;
  inflowMomentTimeslotUser_unsetNoShowStatus?: Maybe<InflowMomentTimeslotUserType>;
  inflowMomentTimeslotUsers_createExportFile?: Maybe<ExportResponseType>;
  inflowInviteTemplates_create?: Maybe<InflowInviteTemplateType>;
  inflowInviteTemplates_edit?: Maybe<InflowInviteTemplateType>;
  inflowInviteTemplates_delete?: Maybe<InflowInviteTemplateType>;
  groupInviteTemplates_create?: Maybe<GroupInviteTemplateType>;
  groupInviteTemplates_edit?: Maybe<GroupInviteTemplateType>;
  groupInviteTemplates_delete?: Maybe<GroupInviteTemplateType>;
  userLearnerSignups_create?: Maybe<UserLearnerSignupType>;
  userLearnerSignups_edit?: Maybe<UserLearnerSignupType>;
  userLearnerSignups_delete?: Maybe<UserLearnerSignupType>;
};


export type RootMutationTypeCalendarEvents_CreateArgs = {
  calendarEvent: CalendarEventInputType;
};


export type RootMutationTypeCalendarEvents_EditArgs = {
  calendarEvent: CalendarEventInputType;
};


export type RootMutationTypeCalendarEvents_DeleteArgs = {
  calendarEventId: Scalars['MongoID'];
};


export type RootMutationTypeCourseMaterials_CreateArgs = {
  courseMaterial: CourseMaterialInputType;
};


export type RootMutationTypeCourseMaterials_EditArgs = {
  courseMaterial: CourseMaterialInputType;
};


export type RootMutationTypeCourseMaterials_DeleteArgs = {
  courseMaterialId: Scalars['MongoID'];
};


export type RootMutationTypeExams_CreateArgs = {
  exam: ExamInputType;
};


export type RootMutationTypeExams_EditArgs = {
  exam: ExamInputType;
};


export type RootMutationTypeExams_DeleteArgs = {
  examId: Scalars['MongoID'];
};


export type RootMutationTypeFileSessions_CreateArgs = {
  fileSession: FileSessionInputType;
};


export type RootMutationTypeGroups_CreateArgs = {
  group: GroupInputType;
};


export type RootMutationTypeGroups_EditArgs = {
  group: GroupInputType;
};


export type RootMutationTypeGroups_DeleteArgs = {
  groupId: Scalars['MongoID'];
};


export type RootMutationTypeGroups_ChangeConceptUsersArgs = {
  groupId: Scalars['MongoID'];
  groupUsersToAdd?: Maybe<Array<AddedGroupUserInputType>>;
  groupUsersToRemove?: Maybe<Array<RemovedGroupUserInputType>>;
};


export type RootMutationTypeGroups_EnrollUserArgs = {
  groupId?: Maybe<Scalars['MongoID']>;
  userId?: Maybe<Scalars['MongoID']>;
  date?: Maybe<Scalars['Date']>;
  role?: Maybe<GroupUserRoleEnum>;
};


export type RootMutationTypeGroups_DisenrollUserArgs = {
  groupId?: Maybe<Scalars['MongoID']>;
  userId?: Maybe<Scalars['MongoID']>;
  date?: Maybe<Scalars['Date']>;
  removedReason?: Maybe<GroupUserRemovedReasonEnum>;
  removedInfo?: Maybe<Scalars['String']>;
  role?: Maybe<GroupUserRoleEnum>;
  followupAction?: Maybe<GroupUserDisenrollmentFollowupActionEnum>;
  newAdviceModule?: Maybe<GroupUserDisenrollmentNewAdviceModuleInputType>;
};


export type RootMutationTypeGroups_UndoEnrollmentArgs = {
  groupId?: Maybe<Scalars['MongoID']>;
  userId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeGroups_UndoDisenrollmentArgs = {
  groupId?: Maybe<Scalars['MongoID']>;
  userId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeGroups_CreateUsersExportFileArgs = {
  groupsFilter?: Maybe<GroupsFilterInputType>;
  filterUsersByGroupTeachers?: Maybe<Scalars['Boolean']>;
  filterUsersByGroupLearners?: Maybe<Scalars['Boolean']>;
  columns?: Maybe<Array<Scalars['String']>>;
};


export type RootMutationTypeGroups_ValidateArgs = {
  groupId: Scalars['MongoID'];
};


export type RootMutationTypeGroups_MakeFinalArgs = {
  groupId: Scalars['MongoID'];
};


export type RootMutationTypeGroups_EndArgs = {
  groupId: Scalars['MongoID'];
  removedGroupUsers?: Maybe<Array<RemovedGroupUserInputType>>;
};


export type RootMutationTypeGroups_AddDocumentsArgs = {
  groupId: Scalars['MongoID'];
  documents?: Maybe<Array<Maybe<GroupDocumentInputType>>>;
};


export type RootMutationTypeGroups_RemoveDocumentsArgs = {
  groupId: Scalars['MongoID'];
  documentIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};


export type RootMutationTypeInstructionVideos_CreateArgs = {
  instructionVideo: InstructionVideoInputType;
};


export type RootMutationTypeInstructionVideos_EditArgs = {
  instructionVideo: InstructionVideoInputType;
};


export type RootMutationTypeInstructionVideos_DeleteArgs = {
  instructionVideoId: Scalars['MongoID'];
};


export type RootMutationTypeInvoices_CreateDebitArgs = {
  invoice: InvoiceInputType;
};


export type RootMutationTypeInvoices_DeleteDebitArgs = {
  invoiceId: Scalars['MongoID'];
};


export type RootMutationTypeInvoices_CreateGroupParticipationCreditInvoiceArgs = {
  userId?: Maybe<Scalars['MongoID']>;
  debitInvoiceId?: Maybe<Scalars['MongoID']>;
  lessonIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  creditDescription?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};


export type RootMutationTypeInvoices_CreateFinalExamCreditInvoiceArgs = {
  userId?: Maybe<Scalars['MongoID']>;
  debitInvoiceId?: Maybe<Scalars['MongoID']>;
  amount?: Maybe<Scalars['Float']>;
  alternativeAmountDescription?: Maybe<Scalars['String']>;
  creditDescription?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};


export type RootMutationTypeInvoices_CreateLessonMaterialsCreditInvoiceArgs = {
  userId?: Maybe<Scalars['MongoID']>;
  debitInvoiceId?: Maybe<Scalars['MongoID']>;
  amount?: Maybe<Scalars['Float']>;
  alternativeAmountDescription?: Maybe<Scalars['String']>;
  creditDescription?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};


export type RootMutationTypeInvoices_DeleteCreditArgs = {
  invoiceId: Scalars['MongoID'];
};


export type RootMutationTypeInvoices_MakeFinalArgs = {
  invoiceId?: Maybe<Scalars['MongoID']>;
  invoiceWillBeManuallySent?: Maybe<Scalars['Boolean']>;
};


export type RootMutationTypeInvoices_Credit_RejectArgs = {
  invoiceId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeInvoices_GeneratePdfArgs = {
  invoiceId: Scalars['MongoID'];
};


export type RootMutationTypeInvoices_UploadSignatureForDuoArgs = {
  invoiceId?: Maybe<Scalars['MongoID']>;
  signatureFile?: Maybe<Scalars['UploadedFile']>;
};


export type RootMutationTypeInvoices_CancelArgs = {
  invoiceId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeInvoices_CreateExportFileArgs = {
  filters?: Maybe<InvoicesFilterInputType>;
};


export type RootMutationTypeLessons_CreateArgs = {
  lesson: LessonInputType;
};


export type RootMutationTypeLessons_EditArgs = {
  lesson: LessonInputType;
};


export type RootMutationTypeLessons_DeleteArgs = {
  lessonId: Scalars['MongoID'];
};


export type RootMutationTypeLessons_SetPresenceArgs = {
  lessonUser: LessonUserInputType;
};


export type RootMutationTypeLocationProperties_CreateArgs = {
  locationProperty: LocationPropertyInputType;
};


export type RootMutationTypeLocationProperties_EditArgs = {
  locationProperty: LocationPropertyInputType;
};


export type RootMutationTypeLocationProperties_DeleteArgs = {
  locationPropertyId: Scalars['MongoID'];
};


export type RootMutationTypeLocations_CreateArgs = {
  location: LocationInputType;
};


export type RootMutationTypeLocations_EditArgs = {
  location: LocationInputType;
};


export type RootMutationTypeLocations_DeleteArgs = {
  locationId: Scalars['MongoID'];
};


export type RootMutationTypeModules_CreateArgs = {
  module: ModuleInputType;
};


export type RootMutationTypeModules_EditArgs = {
  module: ModuleInputType;
};


export type RootMutationTypeModules_DeleteArgs = {
  moduleId: Scalars['MongoID'];
};


export type RootMutationTypeOrganizations_CreateArgs = {
  organization: OrganizationInputType;
};


export type RootMutationTypeOrganizations_EditArgs = {
  organization: OrganizationInputType;
};


export type RootMutationTypeOrganizations_DeleteArgs = {
  organizationId: Scalars['MongoID'];
};


export type RootMutationTypeOrganizations_AddDocumentsArgs = {
  organizationId: Scalars['MongoID'];
  documents?: Maybe<Array<Maybe<OrganizationDocumentInputType>>>;
};


export type RootMutationTypeOrganizations_RemoveDocumentsArgs = {
  organizationId: Scalars['MongoID'];
  documentIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};


export type RootMutationTypePrograms_CreateArgs = {
  program: ProgramInputType;
};


export type RootMutationTypePrograms_EditArgs = {
  program: ProgramInputType;
};


export type RootMutationTypePrograms_DeleteArgs = {
  programId: Scalars['MongoID'];
};


export type RootMutationTypeProjects_CreateArgs = {
  project: ProjectInputType;
};


export type RootMutationTypeProjects_EditArgs = {
  project: ProjectInputType;
};


export type RootMutationTypeProjects_DeleteArgs = {
  projectId: Scalars['MongoID'];
};


export type RootMutationTypeProjectInvoicingSettings_CreateArgs = {
  projectInvoicingSetting?: Maybe<ProjectInvoicingSettingInputType>;
};


export type RootMutationTypeProjectInvoicingSettings_EditArgs = {
  projectInvoicingSetting?: Maybe<ProjectInvoicingSettingInputType>;
};


export type RootMutationTypeProjectInvoicingSettings_DeleteArgs = {
  projectInvoicingSettingId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeRooms_CreateArgs = {
  room: RoomInputType;
};


export type RootMutationTypeRooms_EditArgs = {
  room: RoomInputType;
};


export type RootMutationTypeRooms_DeleteArgs = {
  roomId: Scalars['MongoID'];
};


export type RootMutationTypeSessions_LoginArgs = {
  email?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['String']>;
};


export type RootMutationTypeTimelineEvents_CreateArgs = {
  timelineEvent: TimelineEventInputType;
};


export type RootMutationTypeTimelineEvents_EditArgs = {
  timelineEvent: TimelineEventInputType;
};


export type RootMutationTypeTimelineEvents_DeleteArgs = {
  timelineEventId: Scalars['MongoID'];
};


export type RootMutationTypeUsers_CreateArgs = {
  user: UserInputType;
};


export type RootMutationTypeUsers_EditArgs = {
  user: UserInputType;
};


export type RootMutationTypeUsers_DeleteArgs = {
  userId: Scalars['MongoID'];
};


export type RootMutationTypeUsers_AddDocumentsArgs = {
  userId: Scalars['MongoID'];
  documents?: Maybe<Array<Maybe<UserDocumentInputType>>>;
};


export type RootMutationTypeUsers_RemoveDocumentsArgs = {
  userId: Scalars['MongoID'];
  documentIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};


export type RootMutationTypeUsers_Forgot_PasswordArgs = {
  email?: Maybe<Scalars['String']>;
};


export type RootMutationTypeUsers_Change_PasswordArgs = {
  token?: Maybe<Scalars['String']>;
  currentPassword?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  passwordConfirmation?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type RootMutationTypeUsers_SendInviteArgs = {
  userId: Scalars['MongoID'];
};


export type RootMutationTypeUsers_ResetCognitoTotpMfaArgs = {
  userId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeUsers_ChangeStatusArgs = {
  userId?: Maybe<Scalars['MongoID']>;
  userShouldBeDisabled?: Maybe<Scalars['Boolean']>;
};


export type RootMutationTypeUsers_SetMfaFieldsArgs = {
  userId?: Maybe<Scalars['MongoID']>;
  cognitoUserId?: Maybe<Scalars['String']>;
  cognitoMobileNumber?: Maybe<Scalars['String']>;
};


export type RootMutationTypeUsers_ChangeEmailArgs = {
  userId?: Maybe<Scalars['MongoID']>;
  newEmail?: Maybe<Scalars['String']>;
};


export type RootMutationTypeUsers_SetCognitoSmsMfaArgs = {
  userId?: Maybe<Scalars['MongoID']>;
  mobile?: Maybe<Scalars['String']>;
};


export type RootMutationTypeUsers_CreateExportFileArgs = {
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<UsersFilterInputType>;
  columns?: Maybe<Array<Scalars['String']>>;
};


export type RootMutationTypeUsers_ImportFromEdisaFileArgs = {
  edisaImport: EdisaImportInputType;
};


export type RootMutationTypeUsers_ResetIntakeArgs = {
  learnerUserId: Scalars['MongoID'];
};


export type RootMutationTypeUsers_CreateLessonAttendanceFileArgs = {
  groupId: Scalars['MongoID'];
};


export type RootMutationTypeUsers_CreateLessonTimetableFileArgs = {
  groupId: Scalars['MongoID'];
};


export type RootMutationTypeUsers_CreateLessonInviteFileArgs = {
  groupInviteTemplateId: Scalars['MongoID'];
  groupId: Scalars['MongoID'];
  forGroupUserIds: Array<Maybe<Scalars['MongoID']>>;
  freeText?: Maybe<Scalars['String']>;
  freeTextEnglish?: Maybe<Scalars['String']>;
};


export type RootMutationTypeUsers_SendLessonInvitesArgs = {
  groupInviteTemplateId: Scalars['MongoID'];
  groupId: Scalars['MongoID'];
  forGroupUserIds: Array<Maybe<Scalars['MongoID']>>;
  freeText?: Maybe<Scalars['String']>;
  freeTextEnglish?: Maybe<Scalars['String']>;
};


export type RootMutationTypeUsers_SendAttendanceReminderArgs = {
  teacherUserId: Scalars['MongoID'];
};


export type RootMutationTypeUsers_ActivateInvoicingByDuoArgs = {
  userId: Scalars['MongoID'];
};


export type RootMutationTypeUsers_UpdateInternalDuoDeclarationsArgs = {
  userId: Scalars['MongoID'];
};


export type RootMutationTypeUsers_SelectContextUserAsIntakerArgs = {
  userId: Scalars['MongoID'];
};


export type RootMutationTypeUsers_UpdateEnrollmentDataArgs = {
  learnerUserId: Scalars['MongoID'];
  enrollmentType?: Maybe<LearnerEnrollmentTypeEnum>;
  enrollmentDate?: Maybe<Scalars['Date']>;
};


export type RootMutationTypeUsers_ImportArgs = {
  organizationId?: Maybe<Scalars['MongoID']>;
  file?: Maybe<Scalars['UploadedFile']>;
  force?: Maybe<Scalars['Boolean']>;
};


export type RootMutationTypeUsers_InflowOnHoldSetArgs = {
  userIds: Array<Maybe<Scalars['MongoID']>>;
};


export type RootMutationTypeUsers_InflowOnHoldRemoveArgs = {
  userIds: Array<Maybe<Scalars['MongoID']>>;
};


export type RootMutationTypeEmployee_To_External_TeacherArgs = {
  userId: Scalars['MongoID'];
};


export type RootMutationTypeExternal_Teacher_To_EmployeeArgs = {
  userId: Scalars['MongoID'];
};


export type RootMutationTypeDuoDeclarations_CreateArgs = {
  DUODeclaration: DuoDeclarationInputType;
};


export type RootMutationTypeDuoDeclarations_EditArgs = {
  DUODeclaration: DuoDeclarationInputType;
};


export type RootMutationTypeDuoDeclarations_DeleteArgs = {
  DUODeclarationId: Scalars['MongoID'];
};


export type RootMutationTypeNotes_CreateArgs = {
  note: NoteInputType;
};


export type RootMutationTypeNotes_EditArgs = {
  note: NoteInputType;
};


export type RootMutationTypeNotes_DeleteArgs = {
  noteId: Scalars['MongoID'];
};


export type RootMutationTypeDuoResponse_AddDocumentArgs = {
  file?: Maybe<Scalars['UploadedFile']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type RootMutationTypeResults_CreateArgs = {
  result: ResultInputType;
};


export type RootMutationTypeResults_EditArgs = {
  result: ResultInputType;
};


export type RootMutationTypeResults_DeleteArgs = {
  resultId: Scalars['MongoID'];
};


export type RootMutationTypeResult_EnterMultipleArgs = {
  results?: Maybe<Array<Maybe<ResultInputType>>>;
};


export type RootMutationTypeFinalExams_InsertMultipleArgs = {
  finalExams?: Maybe<FinalExamInputType>;
};


export type RootMutationTypeFinalExams_CancelArgs = {
  finalExamId?: Maybe<Scalars['MongoID']>;
  file?: Maybe<Scalars['UploadedFile']>;
};


export type RootMutationTypeFinalExams_UpdateArgs = {
  type?: Maybe<FinalExamEditTypesEnum>;
  finalExamId?: Maybe<Scalars['MongoID']>;
  date?: Maybe<Scalars['Date']>;
  partAndLevel?: Maybe<FinalExamCombinedPartsAndLevelsEnum>;
  file?: Maybe<Scalars['UploadedFile']>;
};


export type RootMutationTypeFinalExams_UploadDuoResultsArgs = {
  resultsFile?: Maybe<Scalars['UploadedFile']>;
};


export type RootMutationTypeDuoLeanerReports_GenerateArgs = {
  year?: Maybe<Scalars['Int']>;
};


export type RootMutationTypeUserLearnerHours_CreateArgs = {
  userLearnerHour: UserLearnerHourInputType;
};


export type RootMutationTypeUserLearnerHours_EditArgs = {
  userLearnerHour: UserLearnerHourInputType;
};


export type RootMutationTypeUserLearnerHours_DeleteArgs = {
  userLearnerHourId: Scalars['MongoID'];
};


export type RootMutationTypeExports_Generate_CitizenshipArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type RootMutationTypeExports_Generate_OutflowArgs = {
  year?: Maybe<Scalars['Int']>;
};


export type RootMutationTypeExports_Audit_Generate_TeacherArgs = {
  year?: Maybe<Scalars['Int']>;
};


export type RootMutationTypeExports_Audit_Generate_FinalExam_AttemptsArgs = {
  year?: Maybe<Scalars['Int']>;
};


export type RootMutationTypeExports_Participated_Hours_DuoArgs = {
  bsnInput?: Maybe<Scalars['String']>;
};


export type RootMutationTypeConteptGroups_CreateUsersExportFileArgs = {
  filters?: Maybe<GroupsFilterInputType>;
};


export type RootMutationTypeDuoFinalExamResultErrors_ResolveArgs = {
  DUOFinalExamResultErrorId?: Maybe<Scalars['MongoID']>;
  submitter?: Maybe<FinalExamSubmittersEnum>;
};


export type RootMutationTypeInflowModules_CreateArgs = {
  inflowModule: InflowModuleInputType;
};


export type RootMutationTypeInflowModules_EditArgs = {
  inflowModule: InflowModuleInputType;
};


export type RootMutationTypeInflowModules_DeleteArgs = {
  inflowModuleId: Scalars['MongoID'];
};


export type RootMutationTypeInflowMoments_CreateArgs = {
  inflowMoment: InflowMomentInputType;
};


export type RootMutationTypeInflowMoments_EditArgs = {
  inflowMoment: InflowMomentInputType;
};


export type RootMutationTypeInflowMoments_DeleteArgs = {
  inflowMomentId: Scalars['MongoID'];
};


export type RootMutationTypeInflowMoments_MakeFinalArgs = {
  inflowMomentId: Scalars['MongoID'];
};


export type RootMutationTypeInflowMoment_SendInvitesArgs = {
  inflowMomentId?: Maybe<Scalars['MongoID']>;
  invites?: Maybe<Array<Maybe<InflowMomentInviteInvitesInputType>>>;
  inflowInviteTemplateId?: Maybe<Scalars['MongoID']>;
  freeText?: Maybe<Scalars['String']>;
  freeTextEnglish?: Maybe<Scalars['String']>;
};


export type RootMutationTypeInflowMomentTimeslots_AddArgs = {
  inflowMomentId?: Maybe<Scalars['MongoID']>;
  insertType?: Maybe<InflowMomentTimeslotsAddType>;
};


export type RootMutationTypeInflowMomentTimeslots_ChangeUsersArgs = {
  inflowMomentTimeslotId?: Maybe<Scalars['MongoID']>;
  userIds?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};


export type RootMutationTypeInflowMomentTimeslots_Multiple_ChangeUsersArgs = {
  inflowMomentId?: Maybe<Scalars['MongoID']>;
  inflowMomentTimeslots?: Maybe<Array<Maybe<InflowMomentTimeslotInputType>>>;
};


export type RootMutationTypeAdviceReports_CreateArgs = {
  adviceReport: AdviceReportInputType;
};


export type RootMutationTypeAdviceReports_EditArgs = {
  adviceReport: AdviceReportInputType;
};


export type RootMutationTypeAdviceReports_DeleteArgs = {
  adviceReportId: Scalars['MongoID'];
};


export type RootMutationTypeAdviceReport_RevokeArgs = {
  adviceReportId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeAdviceReport_ReleaseArgs = {
  adviceReportId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeAdviceReports_GenerateFileArgs = {
  adviceReportId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeContracts_TerminateArgs = {
  contractId?: Maybe<Scalars['MongoID']>;
  terminationDate?: Maybe<Scalars['Date']>;
  terminatedReason?: Maybe<Scalars['String']>;
};


export type RootMutationTypeContracts_MakeFinalArgs = {
  contractId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeContracts_UploadSignatureArgs = {
  contractId?: Maybe<Scalars['MongoID']>;
  signatureFile?: Maybe<Scalars['UploadedFile']>;
};


export type RootMutationTypeContracts_RefreshForUserArgs = {
  userId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeInflowMomentTimeslotUser_SetNoShowStatusArgs = {
  inflowMomentTimeslotId?: Maybe<Scalars['MongoID']>;
  userId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeInflowMomentTimeslotUser_UnsetNoShowStatusArgs = {
  inflowMomentTimeslotId?: Maybe<Scalars['MongoID']>;
  userId?: Maybe<Scalars['MongoID']>;
};


export type RootMutationTypeInflowMomentTimeslotUsers_CreateExportFileArgs = {
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<InflowMomentFilterInputType>;
};


export type RootMutationTypeInflowInviteTemplates_CreateArgs = {
  inflowInviteTemplate: InflowInviteTemnplateInputType;
};


export type RootMutationTypeInflowInviteTemplates_EditArgs = {
  inflowInviteTemplate: InflowInviteTemnplateInputType;
};


export type RootMutationTypeInflowInviteTemplates_DeleteArgs = {
  inflowInviteTemplateId: Scalars['MongoID'];
};


export type RootMutationTypeGroupInviteTemplates_CreateArgs = {
  groupInviteTemplate: GroupInviteTemnplateInputType;
};


export type RootMutationTypeGroupInviteTemplates_EditArgs = {
  groupInviteTemplate: GroupInviteTemnplateInputType;
};


export type RootMutationTypeGroupInviteTemplates_DeleteArgs = {
  groupInviteTemplateId: Scalars['MongoID'];
};


export type RootMutationTypeUserLearnerSignups_CreateArgs = {
  userLearnerSignup: UserLearnerSignupInputType;
};


export type RootMutationTypeUserLearnerSignups_EditArgs = {
  userLearnerSignup: UserLearnerSignupInputType;
};


export type RootMutationTypeUserLearnerSignups_DeleteArgs = {
  userLearnerSignupId: Scalars['MongoID'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  addresses_usedCities?: Maybe<Array<Maybe<Scalars['String']>>>;
  adviceReports?: Maybe<Array<Maybe<AdviceReportType>>>;
  calendarEvents?: Maybe<Array<Maybe<CalendarEventType>>>;
  courseMaterials?: Maybe<Array<Maybe<CourseMaterialType>>>;
  DUOFinalExamResultErrors_lastImport?: Maybe<Array<Maybe<DuoFinalExamResultErrorType>>>;
  DUOFinalExamResultErrors_count?: Maybe<Scalars['Int']>;
  DUOFinalExamResultErrors?: Maybe<Array<Maybe<DuoFinalExamResultErrorType>>>;
  DUOInvoicesAvailable?: Maybe<Scalars['Int']>;
  DUOInvoices?: Maybe<Array<Maybe<DuoInvoiceType>>>;
  DUOLearnerReports?: Maybe<Array<Maybe<DuoLearnerReportType>>>;
  DUOResponses?: Maybe<Array<Maybe<DuoResponseType>>>;
  edisaImports_lastImport?: Maybe<EdisaImportType>;
  exams?: Maybe<Array<Maybe<ExamType>>>;
  exports?: Maybe<Array<Maybe<ExportType>>>;
  exports_lastDates?: Maybe<ExportLastDatesType>;
  finalExams?: Maybe<Array<Maybe<FinalExamType>>>;
  fullDutchLocationByStreetAndZipcode?: Maybe<FullDutchLocation>;
  groups?: Maybe<Array<Maybe<GroupType>>>;
  inflowModules?: Maybe<Array<Maybe<InflowModuleType>>>;
  inflowMoments?: Maybe<Array<Maybe<InflowMomentType>>>;
  inflowMoments_validate?: Maybe<ValidationResponseType>;
  info?: Maybe<InfoType>;
  instructionVideos?: Maybe<Array<Maybe<InstructionVideoType>>>;
  invoices?: Maybe<Array<Maybe<InvoiceType>>>;
  paginatedInvoices?: Maybe<PaginatedInvoiceType>;
  locationProperties?: Maybe<Array<Maybe<LocationPropertyType>>>;
  locations?: Maybe<Array<Maybe<LocationType>>>;
  modules?: Maybe<Array<Maybe<ModuleType>>>;
  notes?: Maybe<Array<Maybe<NoteType>>>;
  organizations?: Maybe<Array<Maybe<OrganizationType>>>;
  plannedAbilityExams?: Maybe<Array<Maybe<PlannedAbilityExamType>>>;
  process?: Maybe<Array<Maybe<ProcessType>>>;
  prognosisYears?: Maybe<Array<Maybe<PrognosisYearsType>>>;
  programs?: Maybe<Array<Maybe<ProgramType>>>;
  projects?: Maybe<Array<Maybe<ProjectType>>>;
  revenueYears?: Maybe<Array<Maybe<RevenueYearType>>>;
  statistics?: Maybe<StatisticsType>;
  twinfield_customer?: Maybe<TwinfieldCustomerType>;
  twinfield_article?: Maybe<TwinfieldArticleType>;
  users?: Maybe<Array<Maybe<UserType>>>;
  paginatedUsers?: Maybe<PaginatedUserType>;
  user?: Maybe<UserType>;
  inflowInviteTemplates?: Maybe<Array<Maybe<InflowInviteTemplateType>>>;
  groupInviteTemplates?: Maybe<Array<Maybe<GroupInviteTemplateType>>>;
};


export type RootQueryTypeAddresses_UsedCitiesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type RootQueryTypeAdviceReportsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  byInflowMomentId?: Maybe<Scalars['MongoID']>;
  byLearnerUserId?: Maybe<Scalars['MongoID']>;
};


export type RootQueryTypeCalendarEventsArgs = {
  type?: Maybe<Scalars['String']>;
};


export type RootQueryTypeCourseMaterialsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type RootQueryTypeDuoFinalExamResultErrors_LastImportArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type RootQueryTypeDuoFinalExamResultErrors_CountArgs = {
  includeResolved?: Maybe<Scalars['Boolean']>;
};


export type RootQueryTypeDuoFinalExamResultErrorsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  includeResolved?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type RootQueryTypeDuoInvoicesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type RootQueryTypeDuoLearnerReportsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type RootQueryTypeDuoResponsesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type RootQueryTypeExamsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filterByModuleId?: Maybe<Scalars['MongoID']>;
};


export type RootQueryTypeExportsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filterByType?: Maybe<ExportTypeEnum>;
  filterByGeneratedStart?: Maybe<Scalars['Date']>;
  filterByGeneratedEnd?: Maybe<Scalars['Date']>;
  filterByDownloadedStart?: Maybe<Scalars['Date']>;
  filterByDownloadedEnd?: Maybe<Scalars['Date']>;
  filterByDownloadedUserId?: Maybe<Scalars['MongoID']>;
};


export type RootQueryTypeFinalExamsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<FinalExamsFilterInputType>;
  byId?: Maybe<Scalars['MongoID']>;
  byUserId?: Maybe<Scalars['MongoID']>;
};


export type RootQueryTypeFullDutchLocationByStreetAndZipcodeArgs = {
  zipcode?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  numberAddition?: Maybe<Scalars['String']>;
};


export type RootQueryTypeGroupsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<GroupsFilterInputType>;
};


export type RootQueryTypeInflowModulesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filterByLocked?: Maybe<Scalars['Boolean']>;
  filterByInUse?: Maybe<Scalars['Boolean']>;
};


export type RootQueryTypeInflowMomentsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<InflowMomentFilterInputType>;
  byId?: Maybe<Scalars['MongoID']>;
};


export type RootQueryTypeInflowMoments_ValidateArgs = {
  inflowMomentId: Scalars['MongoID'];
};


export type RootQueryTypeInstructionVideosArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  byRole?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type RootQueryTypeInvoicesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<InvoicesFilterInputType>;
};


export type RootQueryTypePaginatedInvoicesArgs = {
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<InvoicesFilterInputType>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type RootQueryTypeLocationPropertiesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type RootQueryTypeLocationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type RootQueryTypeModulesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  searchText?: Maybe<Scalars['String']>;
  filterActive?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filterByFinalExamTraining?: Maybe<Scalars['Boolean']>;
  filters?: Maybe<ModulesFiltersInputType>;
};


export type RootQueryTypeNotesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<NotesFilterInputType>;
};


export type RootQueryTypeOrganizationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type RootQueryTypePlannedAbilityExamsArgs = {
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  byId?: Maybe<Scalars['MongoID']>;
  filterByExamId?: Maybe<Scalars['MongoID']>;
  filterByLessonId?: Maybe<Scalars['MongoID']>;
  filterByGroupId?: Maybe<Scalars['MongoID']>;
};


export type RootQueryTypeProcessArgs = {
  userId: Scalars['MongoID'];
};


export type RootQueryTypePrognosisYearsArgs = {
  years?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type RootQueryTypeProgramsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<ProgramsFiltersInputType>;
};


export type RootQueryTypeProjectsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  byId?: Maybe<Scalars['MongoID']>;
  searchText?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type RootQueryTypeRevenueYearsArgs = {
  years: Array<Scalars['Int']>;
};


export type RootQueryTypeTwinfield_CustomerArgs = {
  twinfieldCustomerCode: Scalars['String'];
};


export type RootQueryTypeTwinfield_ArticleArgs = {
  twinfieldArticleCode: Scalars['String'];
};


export type RootQueryTypeUsersArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<UsersFilterInputType>;
};


export type RootQueryTypePaginatedUsersArgs = {
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<UsersFilterInputType>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type RootQueryTypeInflowInviteTemplatesArgs = {
  inflowModuleId?: Maybe<Scalars['MongoID']>;
  byId?: Maybe<Scalars['MongoID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type RootQueryTypeGroupInviteTemplatesArgs = {
  moduleId?: Maybe<Scalars['MongoID']>;
  byId?: Maybe<Scalars['MongoID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};

export type SessionType = {
  __typename?: 'SessionType';
  _id: Scalars['MongoID'];
  token?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
  singleUse?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserType>;
};

export type SignatureFileType = {
  __typename?: 'SignatureFileType';
  _id: Scalars['MongoID'];
  isPDF: Scalars['Boolean'];
  fileName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUser?: Maybe<UserType>;
};

export type StatisticsType = {
  __typename?: 'StatisticsType';
  emailBounces?: Maybe<Array<EmailBounceType>>;
};

export type TeacherInputType = {
  isActive?: Maybe<Scalars['Boolean']>;
  paymentAddress?: Maybe<AddressInputType>;
  paymentIban?: Maybe<Scalars['String']>;
  isNT2Certified?: Maybe<Scalars['Boolean']>;
  NT2CertifiedDate?: Maybe<Scalars['Date']>;
  NT2Certificates?: Maybe<Array<Maybe<FilesInputType>>>;
  lessonCompetence?: Maybe<Scalars['Boolean']>;
  lessonCompetenceDate?: Maybe<Scalars['Date']>;
  lessonCompetenceCertificates?: Maybe<Array<Maybe<FilesInputType>>>;
  hasOwnCompany?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Scalars['String']>;
  companyCocNumber?: Maybe<Scalars['String']>;
  companyVatNumber?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TimelineEventInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  owner?: Maybe<TimelineEventOwnerInputType>;
  type?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  typeData?: Maybe<TimelineEventTypeDataInputType>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TimelineEventLogTypeDataType = {
  __typename?: 'TimelineEventLogTypeDataType';
  invoice?: Maybe<InvoiceType>;
};

export enum TimelineEventLogTypeEnum {
  InvoiceSent = 'INVOICE_SENT',
  InvoicePaymentPaid = 'INVOICE_PAYMENT_PAID',
  InvoiceReminderSent = 'INVOICE_REMINDER_SENT'
}

export type TimelineEventOwnerInputType = {
  type?: Maybe<TimelineEventOwnerTypeEnum>;
  typeData?: Maybe<TimelineEventOwnerTypeDataInputType>;
  targets?: Maybe<Array<TimelineEventOwnerTargetEnum>>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum TimelineEventOwnerTargetEnum {
  GroupTeacher = 'GROUP_TEACHER',
  Teacher = 'TEACHER',
  LearnerIntaker = 'LEARNER_INTAKER',
  OrganizationContact = 'ORGANIZATION_CONTACT',
  RelatedOrganizationContact = 'RELATED_ORGANIZATION_CONTACT'
}

export type TimelineEventOwnerType = {
  __typename?: 'TimelineEventOwnerType';
  type?: Maybe<TimelineEventOwnerTypeEnum>;
  targets?: Maybe<Array<TimelineEventOwnerTargetEnum>>;
};

export type TimelineEventOwnerTypeDataInputType = {
  userId?: Maybe<Scalars['MongoID']>;
  organizationId?: Maybe<Scalars['MongoID']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum TimelineEventOwnerTypeEnum {
  LearnerUser = 'LEARNER_USER',
  TeacherUser = 'TEACHER_USER',
  Organization = 'ORGANIZATION'
}

export type TimelineEventType = {
  __typename?: 'TimelineEventType';
  _id: Scalars['MongoID'];
  owner?: Maybe<TimelineEventOwnerType>;
  type?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  typeData?: Maybe<TimelineEventTypeDataType>;
  isLog?: Maybe<Scalars['Boolean']>;
  logType?: Maybe<TimelineEventLogTypeEnum>;
  logTypeData?: Maybe<TimelineEventLogTypeDataType>;
  createdByUser?: Maybe<UserType>;
};

export type TimelineEventTypeDataInputType = {
  body?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TimelineEventTypeDataType = {
  __typename?: 'TimelineEventTypeDataType';
  body?: Maybe<Scalars['String']>;
};

export type TwinfieldArticleType = {
  __typename?: 'TwinfieldArticleType';
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  PNL?: Maybe<Scalars['String']>;
  KPL?: Maybe<Scalars['String']>;
};

export type TwinfieldCustomerType = {
  __typename?: 'TwinfieldCustomerType';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type UserDocumentInputType = {
  file?: Maybe<Scalars['UploadedFile']>;
  type?: Maybe<Scalars['String']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserDocumentType = {
  __typename?: 'UserDocumentType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
  isPDF: Scalars['Boolean'];
  type?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdByUser?: Maybe<UserType>;
};

export type UserEmployeeType = {
  __typename?: 'UserEmployeeType';
  isActive?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Maybe<GroupType>>>;
};


export type UserEmployeeTypeGroupsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type UserImportDuplicateReturnType = {
  __typename?: 'UserImportDuplicateReturnType';
  Klantnr?: Maybe<Scalars['String']>;
  Achternaam?: Maybe<Scalars['String']>;
  Voornaam?: Maybe<Scalars['String']>;
  Tussenvoegsel?: Maybe<Scalars['String']>;
  Aanmelding?: Maybe<Scalars['String']>;
  Geaccepteerd?: Maybe<Scalars['String']>;
  Gestart?: Maybe<Scalars['String']>;
  Afgerond?: Maybe<Scalars['String']>;
  Straat?: Maybe<Scalars['String']>;
  Huisnr?: Maybe<Scalars['String']>;
  Postcode?: Maybe<Scalars['String']>;
  Plaats?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Telefoon?: Maybe<Scalars['String']>;
};

export type UserInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  email?: Maybe<Scalars['String']>;
  profile?: Maybe<ProfileInputType>;
  type?: Maybe<Scalars['String']>;
  learner?: Maybe<LearnerInputType>;
  teacher?: Maybe<TeacherInputType>;
  employee?: Maybe<EmployeeInputType>;
  organizationContact?: Maybe<OrganizationContactInputType>;
  mutableRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserIntakerType = {
  __typename?: 'UserIntakerType';
  inflowMoments?: Maybe<Array<Maybe<InflowMomentType>>>;
};

export enum UserIntegrationLawTypeEnum {
  Law2013 = 'law2013',
  Law2021 = 'law2021'
}

export type UserLearnerEdisaType = {
  __typename?: 'UserLearnerEdisaType';
  edisaData?: Maybe<Scalars['String']>;
};

export type UserLearnerFinalExamsFilterInputType = {
  byHasResult?: Maybe<Scalars['Boolean']>;
  byActivatable?: Maybe<Scalars['Boolean']>;
  byExempt?: Maybe<Scalars['Boolean']>;
};

export enum UserLearnerHourDescriptionEnum {
  ELearning = 'E_LEARNING',
  External = 'EXTERNAL',
  Ona = 'ONA'
}

export type UserLearnerHourInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  userId?: Maybe<Scalars['MongoID']>;
  amountOfHours?: Maybe<Scalars['Int']>;
  description?: Maybe<UserLearnerHourDescriptionEnum>;
  document?: Maybe<Scalars['UploadedFile']>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserLearnerHourType = {
  __typename?: 'UserLearnerHourType';
  _id: Scalars['MongoID'];
  userId?: Maybe<Scalars['MongoID']>;
  amountOfHours?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  document?: Maybe<UserLearnerHourTypeDocumentType>;
  createdByUser?: Maybe<UserType>;
};

export type UserLearnerHourTypeDocumentType = {
  __typename?: 'UserLearnerHourTypeDocumentType';
  _id: Scalars['MongoID'];
  fileName?: Maybe<Scalars['String']>;
  isPDF: Scalars['Boolean'];
};

export type UserLearnerHoursSummaryType = {
  __typename?: 'UserLearnerHoursSummaryType';
  hoursCitizenship?: Maybe<Scalars['Int']>;
  hoursCitizenshipOfWhichONA?: Maybe<Scalars['Int']>;
  hoursAlfa?: Maybe<Scalars['Int']>;
  hoursOther?: Maybe<Scalars['Int']>;
  hoursOtherOfWhichONA?: Maybe<Scalars['Int']>;
  hoursTotal?: Maybe<Scalars['Int']>;
};

export type UserLearnerOrganizationType = {
  __typename?: 'UserLearnerOrganizationType';
  organization?: Maybe<OrganizationType>;
  organizationContactUser?: Maybe<UserType>;
  organizationFileNumber?: Maybe<Scalars['String']>;
};

export enum UserLearnerSignupEnrollmentTypeEnum {
  Intake = 'intake',
  Assessment = 'assessment'
}

export type UserLearnerSignupGraphqlType = {
  __typename?: 'UserLearnerSignupGraphqlType';
  _id: Scalars['MongoID'];
  signupDate: Scalars['Date'];
  enrollmentType: UserLearnerSignupEnrollmentTypeEnum;
};

export type UserLearnerSignupInputType = {
  _id?: Maybe<Scalars['MongoID']>;
  userId?: Maybe<Scalars['MongoID']>;
  signupDate?: Maybe<Scalars['Date']>;
  enrollmentType?: Maybe<UserLearnerSignupEnrollmentTypeEnum>;
  nulls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserLearnerSignupType = {
  __typename?: 'UserLearnerSignupType';
  _id: Scalars['MongoID'];
  signupDate: Scalars['Date'];
  enrollmentType: UserLearnerSignupEnrollmentTypeEnum;
};

export type UserLearnerType = {
  __typename?: 'UserLearnerType';
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  totalAttendanceRate?: Maybe<Scalars['Float']>;
  activatableGroupParticipationInvoices?: Maybe<Array<Maybe<ActivatableGroupParticipationInvoicesType>>>;
  groups?: Maybe<Array<Maybe<GroupType>>>;
  finalExams?: Maybe<Array<Maybe<FinalExamType>>>;
  organizations?: Maybe<Array<Maybe<UserLearnerOrganizationType>>>;
  hasPrivatePayment?: Maybe<Scalars['Boolean']>;
  isIntegrationCourse?: Maybe<Scalars['Boolean']>;
  paymentBy?: Maybe<UserPaymentByTypeEnum>;
  integrationLawType?: Maybe<UserIntegrationLawTypeEnum>;
  typeOfLearner?: Maybe<UserTypeOfLearnerTypesEnum>;
  learningRoute?: Maybe<UserLearningRouteTypesEnum>;
  targetLevelPIP?: Maybe<UserTargetLevelTypesEnum>;
  sharePanteia?: Maybe<UserSharePanteiaTypesEnum>;
  hasInflowMomentsAfterEnrollmentDate?: Maybe<Scalars['Boolean']>;
  isAvailableForInflow?: Maybe<Scalars['Boolean']>;
  twinfieldCustomer?: Maybe<TwinfieldCustomerType>;
  canTwinfieldCustomerCodeChange?: Maybe<Scalars['Boolean']>;
  privatePaymentIsDUO?: Maybe<Scalars['Boolean']>;
  privatePaymentDUOHasApprovedFund?: Maybe<Scalars['Boolean']>;
  privatePaymentDUOInitialRemainingFund?: Maybe<Scalars['Float']>;
  privatePaymentDUORemainingLifeFund?: Maybe<Scalars['Float']>;
  canPrivatePaymentDUOInitialRemainingLifeFundBeChangedByContextUser?: Maybe<Scalars['Boolean']>;
  privatePaymentAddress?: Maybe<AddressType>;
  isInvoicingByDUOActive?: Maybe<Scalars['Boolean']>;
  oldFileNumber?: Maybe<Scalars['String']>;
  immigratedAt?: Maybe<PrecisionDateType>;
  isCitizenshipEducationObliged?: Maybe<Scalars['Boolean']>;
  obligedCitizenshipEducationStartDate?: Maybe<PrecisionDateType>;
  obligedCitizenshipEducationEndDate?: Maybe<Scalars['Date']>;
  obligedCitizenshipEducationInitialEndDate?: Maybe<Scalars['Date']>;
  obligedCitizenshipEducationEndDateExtensions?: Maybe<Array<Maybe<ObligedCitizenshipEducationEndDateExtensiontype>>>;
  obligedCitizenshipEducationCompletedAt?: Maybe<Scalars['Date']>;
  obligedCitizenshipEducationCompletedByImport?: Maybe<Scalars['Boolean']>;
  socialStatus?: Maybe<Scalars['String']>;
  socialStatusExtraInfo?: Maybe<Scalars['String']>;
  familyCompositionLanguageExposure?: Maybe<Scalars['String']>;
  nativeLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  homeLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  otherLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  englishAsSupportLanguage?: Maybe<Scalars['String']>;
  workExperienceCountryOfOrigin?: Maybe<Scalars['String']>;
  workExperienceLocal?: Maybe<Scalars['String']>;
  workLiteracy?: Maybe<Scalars['String']>;
  workLiteracyLevel?: Maybe<Scalars['String']>;
  workLiteracyInfo?: Maybe<Scalars['String']>;
  currentOccupationMainLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  currentOccupationVocalInteraction?: Maybe<Scalars['String']>;
  currentOccupationVocalInteractionQuality?: Maybe<Scalars['String']>;
  currentOccupationVocalInteractionInfo?: Maybe<Scalars['String']>;
  currentOccupationStartDate?: Maybe<PrecisionDateType>;
  currentOccupationResponsibilities?: Maybe<Scalars['String']>;
  currentOccupationFunction?: Maybe<Scalars['String']>;
  highestEducationCountryOfOrigin?: Maybe<Scalars['String']>;
  highestEducationLocal?: Maybe<Scalars['String']>;
  highestEducationCountryOfOriginYear?: Maybe<Scalars['Int']>;
  highestEducationLocalYear?: Maybe<Scalars['Int']>;
  educationDesire?: Maybe<Scalars['String']>;
  educationExtraInfo?: Maybe<Scalars['String']>;
  computerSkill?: Maybe<Scalars['String']>;
  NT2HasAttempted?: Maybe<Scalars['Boolean']>;
  NT2Info?: Maybe<Scalars['String']>;
  NT2?: Maybe<Array<Maybe<Nt2Type>>>;
  intakeDate?: Maybe<Scalars['Date']>;
  intakeLiteracy?: Maybe<Scalars['String']>;
  intakeLiteracyNativeLanguage?: Maybe<Scalars['String']>;
  intakeGrades?: Maybe<InflowLevelsType>;
  latestAbilityLevels?: Maybe<LatestAbilityLevelsType>;
  legacyIntakeGrades?: Maybe<LegacyInflowLevelsType>;
  intakeReadingExamScore?: Maybe<Scalars['Int']>;
  intakeReadingExamScoreTotal?: Maybe<Scalars['Int']>;
  intakeGradesLearnability?: Maybe<Scalars['Int']>;
  intakeMotivation?: Maybe<Scalars['String']>;
  adviceModule?: Maybe<ModuleType>;
  adviceModuleFilterByProjectId?: Maybe<Scalars['MongoID']>;
  adviceModuleFilterByProgramId?: Maybe<Scalars['MongoID']>;
  adviceModuleInfo?: Maybe<Scalars['String']>;
  intakeGoals?: Maybe<Array<Maybe<Scalars['String']>>>;
  intakeOtherGoal?: Maybe<Scalars['String']>;
  intakeGoalsAdditionalInfo?: Maybe<Scalars['String']>;
  preferredLocations?: Maybe<Array<Maybe<LocationType>>>;
  intakeResultsInfo?: Maybe<Scalars['String']>;
  howDoYouKnowTopTaal?: Maybe<Scalars['String']>;
  examDignityDate?: Maybe<Scalars['Date']>;
  availabilityCalendarEvents?: Maybe<Array<CalendarEventType>>;
  edisa?: Maybe<UserLearnerEdisaType>;
  invoices?: Maybe<Array<Maybe<InvoiceType>>>;
  latestInternalDUODeclarationsUpdateDate?: Maybe<Scalars['Date']>;
  DUODeclarations?: Maybe<Array<Maybe<DuoDeclarationType>>>;
  finalExamNeeded?: Maybe<FinalExamNeededType>;
  hours?: Maybe<Array<Maybe<UserLearnerHourType>>>;
  hoursPresent?: Maybe<Scalars['Int']>;
  eLearningHours?: Maybe<Scalars['Int']>;
  hoursSummary?: Maybe<UserLearnerHoursSummaryType>;
  inflowMoments?: Maybe<Array<Maybe<InflowMomentType>>>;
  enrollmentDate?: Maybe<Scalars['Date']>;
  enrollmentType?: Maybe<LearnerEnrollmentTypeEnum>;
  signups: Array<UserLearnerSignupGraphqlType>;
  adviceReports?: Maybe<Array<Maybe<AdviceReportType>>>;
  inflowMomentsNoShowStreak?: Maybe<Scalars['Int']>;
  contractCount?: Maybe<Scalars['Int']>;
  contracts?: Maybe<Array<Maybe<ContractType>>>;
  isAlreadyInInflowMoment?: Maybe<Scalars['Boolean']>;
  isWaitingForInflow?: Maybe<Scalars['Boolean']>;
  isInflowOnHold?: Maybe<Scalars['Boolean']>;
};


export type UserLearnerTypeGroupsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filterByActive?: Maybe<Scalars['Boolean']>;
  filterByGroupIds?: Maybe<Array<Scalars['MongoID']>>;
  filterByActivatable?: Maybe<Scalars['Boolean']>;
};


export type UserLearnerTypeFinalExamsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<UserLearnerFinalExamsFilterInputType>;
};


export type UserLearnerTypeInvoicesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  filters?: Maybe<InvoicesFilterInputType>;
};


export type UserLearnerTypeDuoDeclarationsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type UserLearnerTypeHoursArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type UserLearnerTypeContractsArgs = {
  filterByActiveAndNeeded?: Maybe<Scalars['Boolean']>;
  filterByUnsignedForGroupId?: Maybe<Scalars['MongoID']>;
};

export enum UserLearningRouteTypesEnum {
  Z = 'Z',
  B1 = 'B1',
  B1Intensive = 'B1Intensive',
  Education = 'Education'
}

export type UserOrganizationContactType = {
  __typename?: 'UserOrganizationContactType';
  organization?: Maybe<OrganizationType>;
  hasAcceptedAgreement?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export enum UserPaymentByTypeEnum {
  Organization = 'Organization',
  Private = 'Private',
  SelfReporter = 'SelfReporter',
  FamilyMigrant = 'FamilyMigrant'
}

export type UserProfileType = {
  __typename?: 'UserProfileType';
  initials?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  surName?: Maybe<Scalars['String']>;
  surNamePrefix?: Maybe<Scalars['String']>;
  isMfaConfigured?: Maybe<Scalars['Boolean']>;
  givenName?: Maybe<Scalars['String']>;
  cognitoUserId?: Maybe<Scalars['String']>;
  mfaMobileNumber?: Maybe<Scalars['String']>;
  fullSurName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameAbbreviation?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  countryOfBirth?: Maybe<Scalars['String']>;
  cityOfBirth?: Maybe<Scalars['String']>;
  /** @deprecated This has been replaced by countryOfBirth and cityOfBirth */
  addressOfBirth?: Maybe<AddressType>;
  phoneNumbers?: Maybe<Array<PhoneNumberType>>;
  alternativeEmail?: Maybe<Scalars['String']>;
  address?: Maybe<AddressType>;
  redundantAddressCity?: Maybe<Scalars['String']>;
  visitAddress?: Maybe<AddressType>;
  countriesOfNationality?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasNoCountryOfNationality?: Maybe<Scalars['Boolean']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  bsn?: Maybe<Scalars['String']>;
  bsnVerified?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  timelineEvents?: Maybe<Array<TimelineEventType>>;
};


export type UserProfileTypeTimelineEventsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export enum UserSharePanteiaTypesEnum {
  Ja = 'Ja',
  Nee = 'Nee',
  Onbekend = 'Onbekend',
  Unset = 'Unset'
}

export enum UserTargetLevelTypesEnum {
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2'
}

export type UserTeacherAvailability = {
  __typename?: 'UserTeacherAvailability';
  date: Scalars['String'];
  availabilityMorning: Availability;
  availabilityAfternoon: Availability;
  availabilityEvening: Availability;
};

export type UserTeacherType = {
  __typename?: 'UserTeacherType';
  isActive?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Maybe<GroupType>>>;
  paymentAddress?: Maybe<AddressType>;
  paymentIban?: Maybe<Scalars['String']>;
  isNT2Certified?: Maybe<Scalars['Boolean']>;
  NT2CertifiedDate?: Maybe<Scalars['Date']>;
  NT2Certificates?: Maybe<Array<Maybe<Nt2CertificateFileType>>>;
  lessonCompetence?: Maybe<Scalars['Boolean']>;
  lessonCompetenceDate?: Maybe<Scalars['Date']>;
  lessonCompetenceCertificates?: Maybe<Array<Maybe<LessonCompetenceCertificateFileType>>>;
  hasOwnCompany?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Scalars['String']>;
  companyCocNumber?: Maybe<Scalars['String']>;
  companyVatNumber?: Maybe<Scalars['String']>;
  intakes?: Maybe<Array<UserType>>;
  availabilityCalendarEvents?: Maybe<Array<CalendarEventType>>;
  availability?: Maybe<Array<Maybe<UserTeacherAvailability>>>;
};


export type UserTeacherTypeGroupsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type UserTeacherTypeIntakesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
};


export type UserTeacherTypeAvailabilityArgs = {
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  timezoneOffset: Scalars['Int'];
};

export type UserType = {
  __typename?: 'UserType';
  _id: Scalars['MongoID'];
  email?: Maybe<Scalars['String']>;
  emailForCommunication?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  isLearner?: Maybe<Scalars['Boolean']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isExternalTeacher?: Maybe<Scalars['Boolean']>;
  isIntaker?: Maybe<Scalars['Boolean']>;
  isOrganizationContact?: Maybe<Scalars['Boolean']>;
  isFinancial?: Maybe<Scalars['Boolean']>;
  lastActivityAt?: Maybe<Scalars['Date']>;
  invitedAt?: Maybe<Scalars['Date']>;
  hasExpiredInvoices?: Maybe<Scalars['Boolean']>;
  mayHaveNewInvoices?: Maybe<Scalars['Boolean']>;
  hasInvoicingEnabled?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<UserProfileType>;
  teacher?: Maybe<UserTeacherType>;
  learner?: Maybe<UserLearnerType>;
  employee?: Maybe<UserEmployeeType>;
  intaker?: Maybe<UserIntakerType>;
  organizationContact?: Maybe<UserOrganizationContactType>;
  documents?: Maybe<Array<Maybe<UserDocumentType>>>;
  results?: Maybe<Array<Maybe<ResultType>>>;
  passwordResetRequired?: Maybe<Scalars['Boolean']>;
};


export type UserTypeRolesArgs = {
  filterMutable?: Maybe<Scalars['Boolean']>;
};


export type UserTypeDocumentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDir?: Maybe<Scalars['String']>;
};


export type UserTypeResultsArgs = {
  filterByPlannedAbilityExamId?: Maybe<Scalars['MongoID']>;
};

export enum UserTypeOfLearnerTypesEnum {
  Integration2021 = 'Integration2021',
  Integration2013 = 'Integration2013',
  Webnt1 = 'WEBNT1',
  Webnt2 = 'WEBNT2',
  Other = 'Other'
}

export type UsersFilterInputType = {
  byId?: Maybe<Scalars['MongoID']>;
  roles?: Maybe<Array<Scalars['String']>>;
  everyRoleIn?: Maybe<Array<Scalars['String']>>;
  excludeRoles?: Maybe<Array<Scalars['String']>>;
  searchText?: Maybe<Scalars['String']>;
  excludeUsersInInflowMomentInOtherSlots?: Maybe<ExcludeUsersInInflowMomentIdInputObject>;
  filterByCityNames?: Maybe<Array<Scalars['String']>>;
  filterByOneOfMutableRoles?: Maybe<Array<Scalars['String']>>;
  filterByGroupIds?: Maybe<Array<Scalars['MongoID']>>;
  filterByEnrolledGroupIds?: Maybe<Array<Scalars['MongoID']>>;
  filterByRoleInGroup?: Maybe<Scalars['String']>;
  filterByOrganizationIds?: Maybe<Array<Scalars['MongoID']>>;
  filterByOrganizationContactUserIds?: Maybe<Array<Scalars['MongoID']>>;
  filterByContactOrganizationIds?: Maybe<Array<Scalars['MongoID']>>;
  filterByAdviceModuleIds?: Maybe<Array<Scalars['MongoID']>>;
  filterByPreferredLocationIds?: Maybe<Array<Scalars['MongoID']>>;
  filterByLearnabiltyMin?: Maybe<Scalars['Int']>;
  filterByLearnabiltyMax?: Maybe<Scalars['Int']>;
  filterEmployeeActive?: Maybe<Scalars['Boolean']>;
  filterLearnerActive?: Maybe<Scalars['Boolean']>;
  filterTeacherActive?: Maybe<Scalars['Boolean']>;
  filterOrganizationContactActive?: Maybe<Scalars['Boolean']>;
  filterTeacherNT2Certification?: Maybe<Scalars['Boolean']>;
  filterTeacherLessonCompetence?: Maybe<Scalars['Boolean']>;
  filterLearnerStatuses?: Maybe<Array<Scalars['String']>>;
  filterByFinalExamNeeded?: Maybe<Scalars['Boolean']>;
  finalExamNeededFilters?: Maybe<FinalExamsNeededFilterInputType>;
  filterActive?: Maybe<Scalars['Boolean']>;
  filterByIsAvailableForInflow?: Maybe<Scalars['Boolean']>;
  filterByHasNoInflowMomentsAfterEnrollmentDate?: Maybe<Scalars['Boolean']>;
  filterByEnrollmentTypeIntake?: Maybe<Scalars['Boolean']>;
  filterByEnrollmentTypeAssessment?: Maybe<Scalars['Boolean']>;
  filterByEnrollmentDateFrom?: Maybe<Scalars['Date']>;
  filterByEnrollmentDateTo?: Maybe<Scalars['Date']>;
  inflowMomentCandidateTableSort?: Maybe<Scalars['Boolean']>;
  filterByTeacherHasOwnCompany?: Maybe<Scalars['Boolean']>;
};

export type ValidationErrorType = {
  __typename?: 'ValidationErrorType';
  key?: Maybe<Scalars['String']>;
  stringifiedInfo?: Maybe<Scalars['String']>;
};

export type ValidationResponseType = {
  __typename?: 'ValidationResponseType';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ValidationErrorType>>>;
};

export enum Availability {
  Full = 'FULL',
  Partial = 'PARTIAL',
  None = 'NONE'
}

export type ExcludeUsersInInflowMomentIdInputObject = {
  inflowMomentId?: Maybe<Scalars['MongoID']>;
  inflowMomentTimeslotId?: Maybe<Scalars['MongoID']>;
};

export enum InflowMomentTimeslotsAddType {
  Before = 'before',
  After = 'after'
}

export type AdviceReportsQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  inflowMomentId?: Maybe<Scalars['MongoID']>;
}>;


export type AdviceReportsQuery = (
  { __typename?: 'RootQueryType' }
  & { adviceReports?: Maybe<Array<Maybe<(
    { __typename?: 'AdviceReportType' }
    & Pick<AdviceReportType, '_id' | 'createdAt' | 'updatedAt' | 'releasedAt' | 'levelReading' | 'levelWriting' | 'levelListening' | 'levelTalking' | 'levelConversations'>
    & { file?: Maybe<(
      { __typename?: 'ExportResponseType' }
      & Pick<ExportResponseType, 'fileId'>
    )>, learnerUser?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, '_id'>
      & { profile?: Maybe<(
        { __typename?: 'UserProfileType' }
        & Pick<UserProfileType, 'name'>
      )> }
    )>, intakerUser?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, '_id' | 'roles'>
      & { profile?: Maybe<(
        { __typename?: 'UserProfileType' }
        & Pick<UserProfileType, 'name'>
      )> }
    )>, inflowMoment?: Maybe<(
      { __typename?: 'InflowMomentType' }
      & Pick<InflowMomentType, '_id'>
      & { inflowModule?: Maybe<(
        { __typename?: 'InflowModuleType' }
        & Pick<InflowModuleType, '_id' | 'checkAdviceReportNeeded'>
      )> }
    )> }
  )>>> }
);


export const AdviceReportsDocument = gql`
    query adviceReports($skip: Int, $limit: Int, $inflowMomentId: MongoID) {
  adviceReports(skip: $skip, limit: $limit, byInflowMomentId: $inflowMomentId) {
    _id
    file {
      fileId
    }
    learnerUser {
      _id
      profile {
        name
      }
    }
    intakerUser {
      _id
      roles
      profile {
        name
      }
    }
    createdAt
    updatedAt
    releasedAt
    levelReading
    levelWriting
    levelListening
    levelTalking
    levelConversations
    inflowMoment {
      _id
      inflowModule {
        _id
        checkAdviceReportNeeded
      }
    }
  }
}
    `;

/**
 * __useAdviceReportsQuery__
 *
 * To run a query within a React component, call `useAdviceReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdviceReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdviceReportsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      inflowMomentId: // value for 'inflowMomentId'
 *   },
 * });
 */
export function useAdviceReportsQuery(baseOptions?: Apollo.QueryHookOptions<AdviceReportsQuery, AdviceReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdviceReportsQuery, AdviceReportsQueryVariables>(AdviceReportsDocument, options);
      }
export function useAdviceReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdviceReportsQuery, AdviceReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdviceReportsQuery, AdviceReportsQueryVariables>(AdviceReportsDocument, options);
        }
export type AdviceReportsQueryHookResult = ReturnType<typeof useAdviceReportsQuery>;
export type AdviceReportsLazyQueryHookResult = ReturnType<typeof useAdviceReportsLazyQuery>;
export type AdviceReportsQueryResult = Apollo.QueryResult<AdviceReportsQuery, AdviceReportsQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ProcessItemType": [
      "AdviceReportType",
      "GroupType",
      "FinalExamType"
    ]
  }
};
      export default result;
    